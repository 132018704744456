// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import FontAwesome from 'react-fontawesome';
import MaterialButton from '@material-ui/core/Button';
import CommonUtilities from '../../utilities/commonUtilities';

// Props and state
export interface ILocalProps {
    className?: string;
    color?: 'default' | 'inherit' | 'primary' | 'secondary';
    content?: string;
    danger?: boolean;
    hidden?: boolean;
    iconName?: string;
    id?: string;
    isDisabled?: boolean;
    onClick?: any;
    size?: 'small' | 'medium' | 'large';
    style?: any;
    title?: string;
    variant?: 'contained' | 'fab' | 'outlined';
}

const ButtonWrapper: any = styled.div`
    display: inline !important;
    margin: 0 0.25em;
`;

const IconWrapperNoContent: any = styled.div`
    display: inline !important;
`;

const IconWrapperWithContent: any = styled.div`
    display: inline !important;
    margin-right: 0.5em !important;
`;

type ComponentProps = ILocalProps & { children?: React.ReactNode };

const Button: React.StatelessComponent<ComponentProps> = (props): JSX.Element => {
    const {
        children,
        className,
        color,
        content,
        hidden,
        iconName,
        id,
        isDisabled,
        onClick,
        size,
        style,
        title,
        variant,
    } = props;

    const IconWrapper: any = content ? IconWrapperWithContent : IconWrapperNoContent;

    if (hidden) {
        return null;
    }

    const buttonId: string = id || CommonUtilities.createGUID();

    return (
        <ButtonWrapper title={title}>
            <MaterialButton
                className={className}
                color={color ? color : 'secondary'}
                disabled={isDisabled}
                id={buttonId}
                onClick={onClick}
                size={size || 'medium'}
                style={style ? style : { borderRadius: '12px' }}
                variant={variant ? variant : 'contained'}
            >
                {iconName && (
                    <IconWrapper>
                        <FontAwesome name={iconName} />
                    </IconWrapper>
                )}
                {children}
                {content}
            </MaterialButton>
        </ButtonWrapper>
    );
};

export default observer(Button);
