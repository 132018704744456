// React
import * as React from 'react';

// Third-party components
import styled from '../../../content/theme';

// Core components
import HttpService, { IResponseObject } from '../../services/httpService';

// Props and state

interface ILocalProps {
    appStoreVersion: string;
    environment: string;
    showOnlyClientVersion: boolean;
}

interface ILocalState {
    packageVersion: string;
    clientOnlyVersion: string;
    hasData: boolean;
}

// End Props and state

// Begin Styled Components

const VersionFooter: any = styled.div`
    color: #484848;
    padding-top: 10px;
    text-align: center;
`;

// End Styled components

class DockerImageVersions extends React.Component<ILocalProps, ILocalState> {
    constructor(props: ILocalProps) {
        super(props);

        this.state = {
            packageVersion: '',
            clientOnlyVersion: '',
            hasData: false,
        };
    }

    // Lifecycle events

    public componentDidMount(): void {
        HttpService.get('DockerStackData/GetPackageVersion').then((response: IResponseObject) => {
            if (response.data) {
                this.setState({
                    hasData: true,
                    packageVersion: response.data,
                    clientOnlyVersion: response.data
                        .split('|')[0]
                        .split('Client:')[1]
                        .trim(),
                });
            }
        });
    }

    // End Lifecycle events

    public render(): JSX.Element {
        if (
            this.state.hasData &&
            this.state.clientOnlyVersion.length > 0 &&
            !this.props.showOnlyClientVersion
        ) {
            return <VersionFooter>{this.state.packageVersion}</VersionFooter>;
        } else if (
            this.state.hasData &&
            this.state.clientOnlyVersion.length > 0 &&
            this.props.showOnlyClientVersion
        ) {
            return <span>{this.state.clientOnlyVersion}</span>;
        } else if (
            (!this.state.hasData || this.state.clientOnlyVersion.length === 0) &&
            this.props.showOnlyClientVersion
        ) {
            return <span>{this.props.appStoreVersion} </span>;
        }

        return <VersionFooter />;
    }
}

export default DockerImageVersions;
