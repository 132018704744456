// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party
import styled from '../../../content/theme';

// Styled components

const StyledLabel: any = styled.label`
    color: #333 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    padding-bottom: 5px !important;
`;

// End Styled components

// Props

export interface ILocalProps {
    className?: string;
    text: string;
}

// End Props

const Label: React.StatelessComponent<ILocalProps> = ({ className, text }): JSX.Element => (
    <StyledLabel className={className}>{text}</StyledLabel>
);

export default observer(Label);
