// Third-party components
import styled, { MiscStyles } from '../../../content/theme';

const ToolbarWrapper: any = styled.div`
    background-color: ${MiscStyles.backgrounds.primaryOffset} !important;
    border-bottom: 1px solid #bbb;
    margin-bottom: 4px;
    height: 48px;
    width: 100%;
`;

export default ToolbarWrapper;
