// Third-party components
import uuidv4 from 'uuid/v4';
import NotificationService from '../services/notificationService';

const CommonUtilities = {
    createGUID(): string {
        return uuidv4().toUpperCase();
    },

    exportFormats: {
        PDF: 'pdf',
        PNG: 'png',
    },

    showChangesCanceled(): void {
        NotificationService.showSuccess('Your changes were canceled.');
    },

    showSaveSucceeded(): void {
        NotificationService.showSuccess('Your changes were saved.');
    },

    showSaveSucceededMessage(message: string): void {
        NotificationService.showSuccess(message);
    },

    showSaveErrorOccurred(): void {
        NotificationService.showError('An error occurred saving your changes.');
    },

    showDataLoadErrorOccurred(): void {
        NotificationService.showError('An error occurred loading the data.');
    },

    showCustomErrorMessage(message: string): void {
        NotificationService.showError(message);
    },

    configurationItemIds: {
        BasicDataTypes: '739cccb6-6f42-48bc-9193-1f0cb29d5880',
        FileType_Delimited: '83395631-a4ca-4efe-9fa0-5ab2909bb46f',
        FileType_Excel: '0e7ce6aa-d60d-4dea-892f-5267de4b62af',
        JobDefinitionType_API: '590df133-065e-4fc4-b19a-8e64e84123a4',
        JobDefinitionType_SelectiveUpdate: '2eebedbb-d7ec-4cc2-bcb9-455191db6205',
        JobDefinitionTypes: 'e0a405a6-427c-45a7-a32f-cb4db7fc1df0',
        JobRunType_Validate: '0c8f8523-8db5-410e-a493-47b6fe25ad4a',
        JobRunType_ValidateAndImport: '8e18699a-85dc-4b7c-ba02-3171cd250913',
        MenuItemType_ExternalLink: '503c6b12-5970-e611-9c89-08626633cefa',
        MenuItemType_Grouping: '12792959-a7d2-4d17-a5e7-a3a487b27124',
        MenuItemType_Screen: '1f42912c-1226-4bfd-a83b-aef5f21ea263',
        MenuItemTypes: '7ce7d4a0-f22a-4f10-ac67-52e734d2609e',
        SQLDataTypes: '739cccb6-6f42-48bc-9193-1f0cb29d5881',
        SourceSystems: '5f997970-27ac-4596-8765-c64dd7e3d6c4',
        Statuses: '45f5b8d4-9ceb-4e41-8b97-eb61c13a94fa',
    },

    claimTypes: {
        Role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
        ControlPointPermission:
            'http://schemas.amtdirect.com/ws/2017/03/identity/claims/controlpointpermission',
    },
};

export default CommonUtilities;
