// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// Core services and utilities
import CommonUtilities from '../../utilities/commonUtilities';

// Local components
import Label from '../forms/Label';

// Styled components

const Wrapper: any = styled.div`
    display: flex;
    flex-direction: column;
`;

// End Styled components

// Props

export interface ILocalProps {
    className?: string;
    id?: string;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    isRequired?: boolean;
    items: any;
    itemTextName: string;
    itemValueName: string;
    label?: string;
    onChange: any;
    onPressEnter?: any;
    placeholder?: string;
    propertyName?: string;
    style?: any;
    value: any;
}

// End Props

const DropDownInput: React.StatelessComponent<ILocalProps> = (props): JSX.Element => {
    const {
        className,
        id,
        isDisabled,
        isReadOnly,
        isRequired,
        itemTextName,
        itemValueName,
        items,
        label,
        onChange,
        onPressEnter,
        placeholder,
        propertyName,
        style,
        value,
    } = props;

    const onLocalChange = (event: any): void => {
        if (propertyName) {
            onChange(propertyName, event.target.value);
        } else {
            onChange(event.target.value);
        }
    };

    const onKeyPress = (event: any): void => {
        if (onPressEnter && event.charCode === 13) {
            onPressEnter();
        }
    };

    const listComponents: JSX.Element[] = items
        ? items.map(
              (item: any, index: number): JSX.Element => (
                  <MenuItem key={index} value={item[itemValueName]}>
                      {item[itemTextName]}
                  </MenuItem>
              )
          )
        : [];

    const inputStyle: any = style ? style : {};
    const componentId: string = id || CommonUtilities.createGUID();

    if (isReadOnly) {
        const selectedItem: any = items.find((x: any): any => x[itemValueName] === value);
        const selectedItemText: string = selectedItem ? selectedItem[itemTextName] : null;

        return (
            <Wrapper>
                <span>
                    <div>
                        <Label text={label} />
                    </div>
                    <div>{selectedItemText}</div>
                </span>
            </Wrapper>
        );
    } else {
        return (
            <Wrapper>
                <FormControl>
                    {label && (
                        <InputLabel htmlFor={componentId}>
                            {label}
                            {isRequired ? ' *' : ''}
                        </InputLabel>
                    )}
                    <Select
                        disabled={isDisabled}
                        error={isRequired && !value}
                        inputProps={{
                            id: componentId,
                            className
                        }}
                        onChange={onLocalChange}
                        onKeyPress={onKeyPress}
                        placeholder={placeholder}
                        style={inputStyle}
                        value={value || ''}
                    >
                        {listComponents}
                    </Select>
                </FormControl>
            </Wrapper>
        );
    }
};

export default observer(DropDownInput);
