// React
import * as React from 'react';
import { Redirect } from 'react-router-dom';

// MobX
import { inject, observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';
import logo from '../../../content/images/2016-Logo-Black.png';

// Core components
import Button from '../buttons/Button';
import TextAreaInput from '../inputs/TextAreaInput';
import DockerImageVersions from '../dockerImageVersions/Container';

// Stores
import IApplicationStore from '../../../stores/interfaces/IApplicationStore';
import ISecurityStore from '../../../stores/interfaces/ISecurityStore';
import LoginStore from './LoginStore';
import ILoginStore from './ILoginStore';

// Styled components

const Wrapper = styled.div`
    align-items: center;
    background-color: #212121;
    display: flex;
    height: 100vh;
    justify-content: center;
`;

const ContentWrapper: any = styled.div`
    align-self: flex-start;
    min-width: 250px;
    width: 30%;
`;

const FormFieldsWrapper = styled.div`
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #eceff1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
    margin-top: 18em;
    padding: 0.5em 1.5em 1.5em 1.5em;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
`;

const Banner: any = styled.div`
    align-items: center;
    color: #769ebc;
    font-size: 100%;
    text-align: center;
`;

const InputWrapper: any = styled.div`
    padding: 0.25em;
`;

const ErrorBlock: any = styled.div`
    color: red;
    padding-left: 0.75em;
`;

const Footer: any = styled.div`
    color: #fafafa;
    font-size: 8px;
    padding-top: 1em;
`;

// End Styled components

// Props
export interface ILocalProps {
    applicationStore?: IApplicationStore;
    location: any;
    securityStore?: ISecurityStore;
}

// End props

@inject('applicationStore', 'securityStore')
@observer
class Login extends React.Component<ILocalProps> {
    private store: ILoginStore;

    constructor(props: ILocalProps) {
        super(props);

        this.store = new LoginStore(this.props.securityStore);
        this.store.clearSession();
    }

    public render() {
        const { from } = this.props.location.state || {
            from: { pathname: '/' },
        };

        const { applicationStore } = this.props;

        if (this.store.shouldRedirectToReferrer) {
            return <Redirect to={from} />;
        }

        return (
            <Wrapper>
                <ContentWrapper>
                    <FormFieldsWrapper>
                        <Banner>
                            <img src={logo} width={230} />
                        </Banner>
                        <InputWrapper>
                            <TextAreaInput
                                id="userNameTextBox"
                                isDisabled={this.store.isBusy}
                                isRequired={true}
                                label="User Name"
                                maxLength={50}
                                onChange={(propertyName: string, propertyValue: string) =>
                                    this.store.onUserNameChanged(propertyValue)
                                }
                                propertyName=""
                                value={this.store.userName}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextAreaInput
                                id="passwordTextBox"
                                isDisabled={this.store.isBusy}
                                isPassword={true}
                                isRequired={true}
                                label="Password"
                                maxLength={50}
                                onChange={(propertyName: string, propertyValue: string) =>
                                    this.store.onPasswordChanged(propertyValue)
                                }
                                onPressEnter={this.store.login}
                                propertyName=""
                                value={this.store.password}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <TextAreaInput
                                id="clientIdTextBox"
                                isDisabled={this.store.isBusy}
                                isRequired={true}
                                label="Client Number"
                                maxLength={10}
                                onChange={(propertyName: string, propertyValue: string) =>
                                    this.store.onClientNumberChanged(propertyValue)
                                }
                                propertyName=""
                                value={this.store.clientNumber}
                            />
                        </InputWrapper>
                        <ErrorBlock>{this.store.errorMessage}</ErrorBlock>
                        <InputWrapper>
                            
                            <Button
                                id="loginButton"
                                content="Log in"
                                isDisabled={
                                    this.store.isBusy ||
                                    !this.store.userName ||
                                    !this.store.password
                                }
                                onClick={this.store.login}
                            />
                        </InputWrapper>
                    </FormFieldsWrapper>
                    <Footer>
                        <span className="pull-left">&copy;2019 - AMTdirect</span>
                        <span className="pull-right">
                            v{' '}
                            <DockerImageVersions
                                appStoreVersion={applicationStore.versionNumber}
                                environment={applicationStore.environment}
                                showOnlyClientVersion={true}
                            />{' '}
                            ({applicationStore.environment})
                        </span>
                    </Footer>
                    <Footer>
                        <DockerImageVersions
                            appStoreVersion={applicationStore.versionNumber}
                            environment={applicationStore.environment}
                            showOnlyClientVersion={false}
                        />
                    </Footer>
                </ContentWrapper>
            </Wrapper>
        );
    }
}

export default Login;

// // React
// import * as React from 'react';
// import { Redirect } from 'react-router-dom';

// // MobX
// import { inject, observer } from 'mobx-react';

// // Third-party components
// import styled from '../../../content/theme';
// import logo from '../../../content/images/2016-Logo-Black.png';

// // Core components
// import DockerImageVersions from '../dockerImageVersions/Container';

// // Local components
// import IApplicationStore from '../../../stores/interfaces/IApplicationStore';
// import ISecurityStore from '../../../stores/interfaces/ISecurityStore';
// import LoginStore from './LoginStore';

// // Styled components

// const Wrapper = styled.div`
//     align-items: center;
//     background-color: #212121;
//     display: flex;
//     height: 100vh;
//     justify-content: center;
// `;

// const ContentWrapper: any = styled.div`
//     align-self: flex-start;
//     min-width: 250px;
//     width: 30%;
// `;

// const FormFieldsWrapper = styled.div`
//     background-color: #fff;
//     border-radius: 5px;
//     border: 1px solid #eceff1;
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
//     margin-top: 18em;
//     padding: 0.5em 1.5em 1.5em 1.5em;
//     transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
//     &:hover {
//         box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
//     }
// `;

// const Banner: any = styled.div`
//     align-items: center;
//     color: #769ebc;
//     font-size: 100%;
//     text-align: center;
// `;

// const InputWrapper: any = styled.div`
//     margin-bottom: 0.4em;
//     padding: 0.75em;
// `;

// const ErrorBlock: any = styled.div`
//     color: red;
//     padding-left: 0.75em;
// `;

// const Footer: any = styled.div`
//     color: #fafafa;
//     font-size: 8px;
//     padding-top: 1em;
// `;

// // End Styled components

// // Props
// export interface ILocalProps {
//     applicationStore?: IApplicationStore;
//     location: any;
//     securityStore?: ISecurityStore;
// }

// // End props

// @inject('applicationStore', 'securityStore')
// @observer
// class Login extends React.Component<ILocalProps> {
//     private store: any;

//     constructor(props: ILocalProps) {
//         super(props);

//         this.store = new LoginStore(this.props.securityStore);
//         this.store.clearSession();
//     }

//     public render() {
//         const { from } = this.props.location.state || {
//             from: { pathname: '/' },
//         };

//         const { applicationStore } = this.props;

//         if (this.store.shouldRedirectToReferrer) {
//             return <Redirect to={from} />;
//         }

//         let content: JSX.Element;

//         if (this.store.errorMessage) {
//             content = <ErrorBlock>{this.store.errorMessage}</ErrorBlock>;
//         } else if (location.search) {
//             this.store.login();
//             content = <div>Logging in</div>;
//         } else if (this.store.addTestUser) {
//             content = (
//                 <InputWrapper>
//                     <div>Select a Test User</div>
//                     <ul>
//                         <li>
//                             <a href="/login?username=o.wrtcghm@aik&clientId=45">
//                                 Cerhi hrigWt - Rack Room Shoes
//                             </a>
//                         </li>
//                     </ul>
//                 </InputWrapper>
//             );
//         }

//         return (
//             <Wrapper>
//                 <ContentWrapper>
//                     <FormFieldsWrapper>
//                         <Banner>
//                             <img src={logo} width={230} />
//                         </Banner>
//                         {content}
//                     </FormFieldsWrapper>
//                     <Footer>
//                         <span className="pull-left">&copy;2019 - AMTdirect</span>
//                         <span className="pull-right">
//                             v{' '}
//                             <DockerImageVersions
//                                 appStoreVersion={applicationStore.versionNumber}
//                                 environment={applicationStore.environment}
//                                 showOnlyClientVersion={true}
//                             />{' '}
//                             ({applicationStore.environment})
//                         </span>
//                     </Footer>
//                     <Footer>
//                         <DockerImageVersions
//                             appStoreVersion={applicationStore.versionNumber}
//                             environment={applicationStore.environment}
//                             showOnlyClientVersion={false}
//                         />
//                     </Footer>
//                 </ContentWrapper>
//             </Wrapper>
//         );
//     }
// }

// export default Login;
