// Styled
import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';

// Material UI
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

interface ITheme {
    themes: {
        [theme: string]: {
            muiTheme: ThemeOptions;
            primary: string;
            secondary: string;
        };
    };
}

interface IMiscColor {
    complementary: string;
    disabled: string;
    inform: string;
    default: string;

    success: string;
    warning: string;
    danger: string;

    backgrounds: {
        primary: string;
        primaryOffset: string;
        secondary: string;
        complementary: string;
        default: string;
        dark: string;
    };

    fonts: {
        fontFamily: {
            header: string;
            subtitle: string;
            paragraph: string;
        };
        colors: {
            primary: string;
            default: string;
            input: string;
            dark: string;
            light: string;
            buttonForeground: string;
        };
    };

    chartColors: string[];
}

const ThemeStyles: ITheme = {
    themes: {
        rust: {
            muiTheme: {
                overrides: {
                    MuiDrawer: {
                        paper: {
                            background: '#dddede',
                            color: '#232122',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                        },
                    },
                    MuiListItemText: {
                        primary: {
                            color: '#000',
                            fontSize: '13px',
                        },
                    },
                    MuiIcon: {
                        root: {
                            color: '#232122',
                            fontSize: '18px',
                            minWidth: '21px',
                            maxWidth: '22px',
                        },
                    },
                    MuiIconButton: {
                        root: {
                            color: '#fff',
                        },
                    },
                },
                palette: {
                    type: 'light',
                    primary: {
                        main: '#aa4b41', // rust
                    },
                    secondary: {
                        main: '#006c84', // cerulean
                    },
                    error: {
                        main: '#ed5752', // flat red
                    },
                },
            },
            primary: '#aa4b41', // match this to muiTheme's primary
            secondary: '#006c84', // match this to muiTheme's secondary
        },
        hamburger: {
            muiTheme: {
                overrides: {
                    MuiDrawer: {
                        paper: {
                            background: '#dddede',
                            color: '#232122',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                        },
                    },
                    MuiListItemText: {
                        primary: {
                            color: '#000',
                            fontSize: '13px',
                        },
                    },
                    MuiIcon: {
                        root: {
                            color: '#232122',
                            fontSize: '18px',
                            minWidth: '21px',
                            maxWidth: '22px',
                        },
                    },
                    MuiIconButton: {
                        root: {
                            color: '#fff',
                        },
                    },
                },
                palette: {
                    type: 'light',
                    primary: {
                        main: '#fbcb7b',
                    },
                    secondary: {
                        main: '#752a07',
                    },
                    error: {
                        main: '#ed5752', // flat red
                    },
                },
            },
            primary: '#aa4b41', // match this to muiTheme's primary
            secondary: '#006c84', // match this to muiTheme's secondary
        },
        default: {
            muiTheme: {
                // typography: {
                //     useNextVariants: true,
                // },
                overrides: {
                    MuiCard: {
                        root: {
                            transition: 'all .1s ease-in',
                            '&:hover': {
                                boxShadow: '1px 4px 5px rgba(0, 0, 0, 0.2)',
                            },
                        },
                    },
                    MuiDrawer: {
                        paper: {
                            background: '#E0E0E0',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                        },
                    },
                    MuiListItemText: {
                        primary: {
                            color: '#232122',
                            fontSize: '13px',
                        },
                    },
                    MuiIcon: {
                        root: {
                            color: '#232122',
                            fontSize: '18px',
                            minWidth: '21px',
                            maxWidth: '22px',
                        },
                    },
                    MuiIconButton: {
                        root: {
                            color: '#fff',
                        },
                    },
                },
                palette: {
                    type: 'light',
                    primary: {
                        main: '#1255AA',
                    },
                    secondary: {
                        main: '#D6741B',
                    },
                    error: {
                        main: '#e55039', // mandarin red
                    },
                },
            },
            primary: '#1255AA', // match this to muiTheme's primary
            secondary: '#D6741B', // match this to muiTheme's secondary
        },
    },
};

// MaterialUI styles
const drawerWidth = 300;

export type ClassNames =
    | 'appBar'
    | 'appBarShift'
    | 'avatar'
    | 'bigAvatar'
    | 'card'
    | 'content'
    | 'drawerPaper'
    | 'drawerPaperClose'
    | 'flex'
    | 'hide'
    | 'menuButton'
    | 'paper'
    | 'root'
    | 'toolbar'
    | 'toolbarHeader';

const styles: StyleRulesCallback<ClassNames> = (theme) => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        borderBottom: '0.12em solid #1255AA',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth - 8}px)`, // subtracting margin left pull on drawerPaper
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    avatar: {
        margin: 6,
        boxShadow: '0 4px 4px 3px rgba(0, 0, 0, .15)',
        backgroundColor: '#fff',
    },
    bigAvatar: {
        backgroundColor: '#fff',
        width: 36,
        height: 36,
        overflow: 'hidden',
    },
    content: {
        flexGrow: 1,
        backgroundColor: '#eee',
        marginTop: '48px',
        overflowX: 'hidden',
    },
    drawerPaper: {
        marginLeft: -8,
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 7.25,
        },
    },
    flex: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        // margin: theme.spacing.unit * 1,
        // position: 'relative',
    },
    card: {
        ...theme.mixins.gutters(),
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 1,
        position: 'relative',
        display: 'inline-block',
        alignItems: 'center',
        textAlign: 'center',
        width: 250,
        height: 250,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    toolbarHeader: {
        minHeight: '48px',
    },
});

const MiscStyles: IMiscColor = {
    complementary: '#B6D5E9', // light blue
    disabled: '#999999', // grey
    inform: '#87B79C', // light green
    default: '#F8F8F8', // off white

    success: '#87B79C', // light green
    warning: '#F5A623', // yellow
    danger: '#DA4D4D', // red

    backgrounds: {
        primary: '#fafafa', // white
        primaryOffset: '#f5f5f5',
        secondary: '#EEEEEE', // medium grey
        complementary: '#dddede', // light grey
        default: '#fafafa', // grey,
        dark: '#232122', // deep ash
    },

    fonts: {
        fontFamily: {
            header: 'Roboto',
            subtitle: 'Roboto',
            paragraph: 'Roboto',
        },
        colors: {
            primary: '#265479', // blue
            default: '#666666', // dark grey
            input: '#000000', // black
            dark: '#000',
            light: '#fff',
            buttonForeground: '#FFFFFF', // white
        },
    },

    chartColors: [
        '#f2c057', // swiss cheese yellow
        '#d13525', // apple red
        '#4c3f54', // fig purple
        '#486824', // basil green
        '#1995ad', // glacier blue
        '#7d4427', // earth
        '#a2c523', // lime
        '#ba5536', // dessert orange
        '#34675c', // teal
        '#2d4262', // indigo
        '#73605b', // taupe
        '#375e97', // sky
        '#f0810f', // tangerine
        '#2ecc71', // Emerald
        '#3498db', // Blue
        '#f1c40f', // Sun Flower yellow
        '#cb0000', // Strawberry
        '#50312f', // eggplant
        '#d35400', // Pumpkin
        '#8e44ad', // Purple
        '#16a085', // Sea Green
        '#d35400', // Maroon Maroon
        '#e67e22', // Orange
        '#9b59b6', // Bright Purple
        '#3dc1d3',
        '#786fa6',
        '#e17055',
        '#0984e3',
        '#d63031',
        '#fd79a8',
        '#00cec9',
        '#fdcb6e',
        '#f6e58d',
        '#4834d4',
    ],
};

const { default: styled, css, ThemeProvider } = (styledComponents as ThemedStyledComponentsModule<
    any
>) as ThemedStyledComponentsModule<ITheme>;

export { MiscStyles, styles, ThemeProvider, ThemeStyles, css };

export default styled;
