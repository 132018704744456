const DateUtilities = {
    formatDate(epoch: number): string {
        if (epoch) {
            return new Intl.DateTimeFormat().format(new Date(epoch));
        } else {
            return null;
        }
    },

    formatDateTime(epoch: number): string {
        if (epoch) {
            return new Date(epoch).toLocaleString('en-US', {}).replace(',', '');
        } else {
            return null;
        }
    },
};

export default DateUtilities;
