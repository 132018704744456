// MobX
import { action, computed, observable } from 'mobx';

// Core utilities and services
import CommonUtilities from '../../core/utilities/commonUtilities';
import HttpService from '../../core/services/httpService';

// Stores
import ISecurityStore from '../interfaces/ISecurityStore';

export interface IClaim {
    type: string;
    value: string;
}

export interface IIdentity {
    claims: IClaim[];
}

export interface IAssertion {
    userId: string;
    clientId?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    isAuthenticated: boolean;
    icon?: string;
    identities?: IIdentity[];
}

class SecurityStore implements ISecurityStore {
    public rights: any = {
        Read: 'CanRead',
        Write: 'CanWrite',
        Archive: 'CanArchive',
        Delete: 'CanDelete',
        Execute: 'CanExecute',
    };

    // Observables
    @observable
    private _assertion: any;

    // Computeds
    @computed
    public get userId(): string {
        return this._assertion && this._assertion.userId
            ? this._assertion.userId.toUpperCase()
            : null;
    }

    @computed
    public get clientId(): string {
        return this._assertion && this._assertion.clientId
            ? this._assertion.clientId.toUpperCase()
            : null;
    }

    @computed
    public get firstName(): string {
        return this._assertion ? this._assertion.firstName : null;
    }

    @computed
    public get lastName(): string {
        return this._assertion ? this._assertion.lastName : null;
    }

    @computed
    public get fullName(): string {
        return this._assertion ? this._assertion.fullName : null;
    }

    @computed
    public get isAuthenticated(): boolean {
        return this._assertion ? this._assertion.isAuthenticated : false;
    }

    @computed
    public get icon(): string {
        return this._assertion ? this._assertion.icon : null;
    }

    @computed
    public get rolesForTokens(): string[] {
        return this.roles.map((role) => `'${role}'`);
    }

    @computed
    public get roles(): string[] {
        const roles: string[] = [];

        const identity = this.getLocalIdentity();
        if (identity && identity.claims && identity.claims.length) {
            identity.claims.forEach((claim: any) => {
                if (claim.type.includes('role')) {
                    roles.push(claim.value);
                }
            });
        }

        return roles.sort(
            (a: string, b: string): number => {
                return a === b ? 0 : a > b ? 1 : -1;
            }
        );
    }

    // Actions
    @action
    public setAssertion = (assertion: any): void => {
        this._assertion = assertion;
    };

    // Functions
    public getAssertion = (): Promise<any> => {
        return HttpService.post('Identity/GetAssertion', {
            shouldForceRefresh: true,
        }).then((response) => {
            if (
                response &&
                !response.error &&
                response.data &&
                response.data.resultCode &&
                response.data.resultCode === 1 &&
                response.data.assertion
            ) {
                this.setAssertion(response.data.assertion);
            }
        });
    };

    public getLocalIdentity = (): any => {
        if (
            this._assertion == null ||
            this._assertion.identities == null ||
            this._assertion.identities.Count === 0
        ) {
            return null;
        }

        return this._assertion.identities[0];
    };

    public userHasPermission = (controlPointName: string, controlPointRight: string): boolean => {
        let hasPermission: boolean = false;
        const localIdentity: any = this.getLocalIdentity();

        if (!localIdentity) {
            return hasPermission;
        }

        const matchingClaim: any = localIdentity.claims.find((claim: any) => {
            return (
                claim.type === CommonUtilities.claimTypes.ControlPointPermission &&
                claim.value.toLowerCase() ===
                    controlPointName.toLowerCase() + '.' + controlPointRight.toLowerCase()
            );
        });

        if (matchingClaim) {
            hasPermission = true;
        }

        return hasPermission;
    };

    public userIsInRole = (roleName: string): boolean => {
        let isInRole: boolean = false;
        const localIdentity: any = this.getLocalIdentity();

        if (!localIdentity) {
            return isInRole;
        }

        const matchingClaim: any = localIdentity.claims.find((claim: any) => {
            return (
                claim.type === CommonUtilities.claimTypes.Role &&
                claim.value.toLowerCase() === roleName.toLowerCase()
            );
        });

        if (matchingClaim) {
            isInRole = true;
        }

        return isInRole;
    };
}

export default SecurityStore;
