// MobX
import { action, observable, runInAction } from 'mobx';

// Core services
import HttpService from '../../services/httpService';
import ISecurityStore from '../../../stores/interfaces/ISecurityStore';

// Interface
import ILoginStore from './ILoginStore';

class Store implements ILoginStore {
    // Internals
    private securityStore: ISecurityStore;

    // Observables
    @observable
    public isBusy: boolean;

    @observable
    public shouldRedirectToReferrer: boolean;

    @observable
    public userName: string;

    @observable
    public password: string;

    @observable
    public clientNumber: string;

    @observable
    public errorMessage: string;

    // Functions
    constructor(securityStore: ISecurityStore) {
        this.securityStore = securityStore;
    }

    // Actions
    @action
    public login = (): void => {
        this.isBusy = true;

        HttpService.post('Identity/Login', {
            userName: this.userName,
            password: this.password,
            clientNumber: this.clientNumber,
        }).then((authenticationResponse) => {
            if (
                authenticationResponse &&
                !authenticationResponse.error &&
                authenticationResponse.data &&
                authenticationResponse.data.token
            ) {
                sessionStorage.setItem('accessToken', authenticationResponse.data.token);

                // TODO - clean up
                HttpService.post('Identity/GetAssertion', {}).then((assertionResponse) => {
                    if (
                        assertionResponse &&
                        !assertionResponse.error &&
                        assertionResponse.data &&
                        assertionResponse.data.assertion
                    ) {
                        runInAction(() => {
                            this.securityStore.setAssertion(assertionResponse.data.assertion);
                            window.sessionStorage.actualUserId = assertionResponse.data.assertion.userId.toUpperCase();

                            runInAction(() => {
                                this.errorMessage = null;
                                this.isBusy = false;
                                this.shouldRedirectToReferrer = true;
                            });

                            return;
                        });
                    } else {
                        runInAction(() => {
                            this.errorMessage =
                                'Authentication failed. Please try again, or contact tech support for assistance.';
                            this.isBusy = false;
                        });
                    }
                });
            } else if (authenticationResponse && authenticationResponse.error) {
                runInAction(() => {
                    this.errorMessage = authenticationResponse.error;
                    this.isBusy = false;
                });
            } else {
                runInAction(() => {
                    this.errorMessage =
                        'Authentication failed. Please try again, or contact tech support for assistance.';
                    this.isBusy = false;
                });
            }
        });
    };

    @action
    public onUserNameChanged = (propertyValue: string): void => {
        this.userName = propertyValue;
    };

    @action
    public onPasswordChanged = (propertyValue: string): void => {
        this.password = propertyValue;
    };

    @action
    public onClientNumberChanged = (propertyValue: string): void => {
        this.clientNumber = propertyValue;
    };

    @action
    public clearSession = (): void => {
        this.shouldRedirectToReferrer = false;
        this.userName = null;
        this.password = null;
        this.clientNumber = null;

        window.sessionStorage.clear();
        this.securityStore.setAssertion(null);
    };
}

export default Store;

// // MobX
// import { action, observable, runInAction } from 'mobx';

// //Third party
// import queryString from 'query-string';

// // Core services
// import HttpService from '../../services/httpService';
// import ISecurityStore from '../../../stores/interfaces/ISecurityStore';

// class Store {
//     // Internals
//     private securityStore: ISecurityStore;

//     // Observables
//     @observable
//     public isBusy: boolean;

//     @observable
//     public shouldRedirectToReferrer: boolean;

//     @observable
//     public userName: string;

//     @observable
//     public password: string;

//     @observable
//     public errorMessage: string;

//     @observable
//     public addTestUser: boolean = location.hostname === 'localhost';

//     // Functions
//     constructor(securityStore: ISecurityStore) {
//         this.securityStore = securityStore;
//     }

//     // Actions
//     @action
//     public login = (): void => {
//         this.isBusy = true;
//         const payload: any = queryString.parse(location.search);

//         if (!payload.clientId) {
//             runInAction(() => {
//                 this.errorMessage =
//                     'Authentication failed. Please try again, or contact tech support for assistance.';
//                 this.isBusy = false;
//             });

//             return;
//         }

//         let endpoint: string;

//         if (payload.username) {
//             endpoint = 'LoginWithUserName';
//         } else if (payload.userId) {
//             endpoint = 'LoginWithUserId';
//         } else {
//             runInAction(() => {
//                 this.errorMessage =
//                     'Authentication failed. Please try again, or contact tech support for assistance.';
//                 this.isBusy = false;
//             });

//             return;
//         }

//         HttpService.post(`Identity/${endpoint}`, payload).then((authenticationResponse) => {
//             if (
//                 authenticationResponse &&
//                 !authenticationResponse.error &&
//                 authenticationResponse.data &&
//                 authenticationResponse.data.token
//             ) {
//                 sessionStorage.setItem('accessToken', authenticationResponse.data.token);

//                 HttpService.post('Identity/GetAssertion', {}).then((assertionResponse) => {
//                     if (
//                         assertionResponse &&
//                         !assertionResponse.error &&
//                         assertionResponse.data &&
//                         assertionResponse.data.assertion
//                     ) {
//                         runInAction(() => {
//                             this.securityStore.setAssertion(assertionResponse.data.assertion);
//                             window.sessionStorage.actualUserId = assertionResponse.data.assertion.userId.toUpperCase();

//                             this.errorMessage = null;
//                             this.isBusy = false;
//                             this.shouldRedirectToReferrer = true;

//                             return;
//                         });
//                     } else {
//                         runInAction(() => {
//                             this.errorMessage =
//                                 'Authentication failed. Please try again, or contact tech support for assistance.';
//                             this.isBusy = false;
//                         });
//                     }
//                 });
//             } else if (authenticationResponse && authenticationResponse.error) {
//                 runInAction(() => {
//                     this.errorMessage = authenticationResponse.error;
//                     this.isBusy = false;
//                 });
//             } else {
//                 runInAction(() => {
//                     this.errorMessage =
//                         'Authentication failed. Please try again, or contact tech support for assistance.';
//                     this.isBusy = false;
//                 });
//             }
//         });
//     };

//     @action
//     public onUserNameChanged = (propertyValue: string): void => {
//         this.userName = propertyValue;
//     };

//     @action
//     public onPasswordChanged = (propertyValue: string): void => {
//         this.password = propertyValue;
//     };

//     @action
//     public clearSession = (): void => {
//         this.shouldRedirectToReferrer = false;
//         this.userName = null;
//         this.password = null;

//         window.sessionStorage.clear();
//         this.securityStore.setAssertion(null);
//     };
// }

// export default Store;
