// React
import * as React from 'react';

// Third-party components
import styled from '../content/theme';

// Core services
import ErrorLogger from '../core/utilities/errorLogger';

// Styled components

const ErrorMessage: any = styled.div`
    font-size: 250%;
    margin-top: 1em;
    text-align: center;
    width: 100%;
`;

// End Styled components

// Props and state

export interface ILocalProps {}

interface ILocalState {
    hasError: boolean;
}

// End Props and state

class ErrorBoundary extends React.Component<ILocalProps, ILocalState> {
    constructor(props: ILocalProps) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    public componentDidCatch(error: any, info: any) {
        // Display fallback UI
        this.setState({ hasError: true });

        ErrorLogger.logComponentDidCatchError(error, info);
    }

    // TODO - Button to refresh?
    public render() {
        if (this.state.hasError) {
            return (
                <div>
                    <ErrorMessage>
                        An application error occurred. Please click your web browser's Refresh
                        button.
                    </ErrorMessage>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
