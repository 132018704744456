// React
import * as React from 'react';

// MobX
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';

// Uncomment to turn on MobX debugging
// Do not leave uncommented when committing
// import DevTools from 'mobx-react-devtools';

// Third party
import classNames from 'classnames';

// React Router
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

// Material UI
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// import Drawer from '@material-ui/core/Drawer';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

// Core services
import appData from './core/services/appData';
import styled, { ClassNames, styles, ThemeStyles } from './content/theme';

// Local components

import Header from './layout/Header';

// Local components
import ErrorBoundary from './layout/ErrorBoundary';
import Login from './core/components/login/Container';
import Routes from './routing/Routes';
import withRoot from './WithRoot';

// Stores
import ISecurityStore from './stores/interfaces/ISecurityStore';

// Styled components / Material UI styles

const AppDiv: any = styled.div``;
const customTheme = createMuiTheme(ThemeStyles.themes.default.muiTheme);

// End styled components

// Props and state

export interface ILocalProps extends RouteComponentProps<{}> {
    routerStore?: RouterStore;
    securityStore?: ISecurityStore;
}

interface ILocalState {
    isDataLoaded: boolean;
}

// End Props and state

@inject('routerStore', 'securityStore')
@observer
class App extends React.Component<ILocalProps & WithStyles<ClassNames>, ILocalState> {
    private isInitialized: boolean = false;

    constructor(props: ILocalProps & WithStyles<ClassNames>) {
        super(props);

        this.state = {
            isDataLoaded: false,
        };
    }

    public componentDidMount() {
        this.initialize();
    }

    public componentDidUpdate() {
        this.initialize();
    }

    private initialize(): void {
        if (
            this.props.securityStore.isAuthenticated &&
            !this.state.isDataLoaded &&
            !this.isInitialized
        ) {
            this.isInitialized = true;

            appData.initialize().then(() => {
                this.setState((prevState: any) => {
                    return {
                        ...prevState,
                        isDataLoaded: true,
                    };
                });
            });
        }
    }

    public render() {
        return (
            <div>
                <MuiThemeProvider theme={customTheme}>
                    <Switch>
                        <Route path="/login" component={Login} />

                        <AppDiv className={this.props.classes.root}>
                            <AppBar
                                position="absolute"
                                // color="default"
                                className={classNames(this.props.classes.appBar)}
                                style={{ background: '#fff' }}
                            >
                                <Header />
                            </AppBar>

                            <main className={this.props.classes.content}>
                                <ErrorBoundary>
                                    {!this.props.securityStore.isAuthenticated ||
                                    (this.props.securityStore.isAuthenticated &&
                                        this.state.isDataLoaded) ? (
                                        <Routes />
                                    ) : (
                                        <div className="loading">Loading&#8230;</div>
                                    )}
                                </ErrorBoundary>
                            </main>
                        </AppDiv>
                    </Switch>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default withRouter(withRoot(withStyles(styles)(App)));
