// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import { Button } from '..';

// Local components
import ClearFix from '../layout/ClearFix';

// Props and state

export interface ILocalProps {
    archiveItem?: Function;
    canArchive: boolean;
    canDelete?: boolean;
    canUnarchive: boolean;
    deleteItem?: Function;
    isBusy: boolean;
    style?: any;
    unarchiveItem?: Function;
}

// End Props and state

// Styled components

const Wrapper: any = styled.div`
    margin-bottom: 0.5em;
    padding: 0 1em;
`;

const ButtonWrapper: any = styled.div`
    display: flex;
    flex-direction: row;
    float: right;
`;

const StatusSpan: any = styled.span`
    font-size: 125%;
    font-weight: bold;
    padding-right: 15px;
`;

const StatusActive: any = styled(StatusSpan)`
    color: green;
`;

const StatusInactive: any = styled(StatusSpan)`
    color: red;
`;

// End Styled components

const showStatus = (canArchive: boolean, canUnarchive: boolean): JSX.Element => {
    if (canArchive) {
        return <StatusActive>Active</StatusActive>;
    } else if (canUnarchive) {
        return <StatusInactive>Archived</StatusInactive>;
    } else {
        return null;
    }
};

const ArchiveDeleteButtonBar: React.StatelessComponent<ILocalProps> = (props): JSX.Element => {
    const {
        archiveItem,
        canArchive,
        canDelete,
        canUnarchive,
        deleteItem,
        isBusy,
        unarchiveItem,
    } = props;

    let archiveButton: JSX.Element;
    if (canArchive && archiveItem) {
        archiveButton = (
            <Button
                className="archive"
                content="Archive"
                iconName="archive"
                isDisabled={isBusy}
                onClick={archiveItem}
            />
        );
    }

    let unarchiveButton: JSX.Element;
    if (canUnarchive && unarchiveItem) {
        unarchiveButton = (
            <Button
                className="unarchive"
                content="Unarchive"
                iconName="recycle"
                isDisabled={isBusy}
                onClick={unarchiveItem}
            />
        );
    }

    let deleteButton: JSX.Element;
    if (canDelete && deleteItem) {
        deleteButton = (
            <Button content="Delete" iconName="trash-o" isDisabled={isBusy} onClick={deleteItem}/>
        );
    }

    return (
        <Wrapper>
            <ButtonWrapper>
                {showStatus(canArchive, canUnarchive)}
                {deleteButton}
                {archiveButton}
                {unarchiveButton}
            </ButtonWrapper>
            <ClearFix />
        </Wrapper>
    );
};

export default observer(ArchiveDeleteButtonBar);
