// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import { DatePicker } from '@progress/kendo-react-dateinputs';

// Local components
import DateUtilities from '../../utilities/dateUtilities';
import Label from '../forms/Label';

// Props and state

export interface ILocalProps {
    defaultValue?: number;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    isRequired?: boolean;
    label: string;
    onChange?: Function;
    propertyName: string;
    maxDate?: number;
    minDate?: number;
    value: any;
}

// End Props and state

// Styled components

let Wrapper: any = styled.div`
    display: flex;
    flex-direction: column;
`;

const InputDiv: any = styled.div`
    margin-top: 2px;
`;

// End Styled components

const DatePickerInput: React.StatelessComponent<ILocalProps> = (props): JSX.Element => {
    const {
        isDisabled,
        isReadOnly,
        isRequired,
        label,
        maxDate,
        minDate,
        onChange,
        propertyName,
        value,
    } = props;

    const onLocalChange = (event: any): void => {
        if (onChange) {
            let epochValue = event.target.value ? event.target.value.valueOf() : null;

            if (epochValue) {
                if (minDate !== null && epochValue < minDate) {
                    epochValue = minDate.valueOf();
                } else if (maxDate !== null && epochValue > maxDate) {
                    epochValue = maxDate.valueOf();
                }
            }

            onChange(propertyName, epochValue);
        }
    };

    if (isRequired && !value && !isDisabled) {
        Wrapper = styled(Wrapper)`
            .k-picker-wrap {
                border-color: red !important;
            }
        `;
    } else {
        Wrapper = styled(Wrapper)`
            .k-picker-wrap {
                border-color: rgba(0, 0, 0, 0.08) !important;
            }
        `;
    }

    if (isReadOnly) {
        let formattedPropertyValue: string;

        if (value) {
            formattedPropertyValue = DateUtilities.formatDateTime(value);
        }

        return (
            <Wrapper>
                {label ? <Label text={label} /> : null}
                <div>{formattedPropertyValue}</div>
            </Wrapper>
        );
    } else {
        return (
            <Wrapper>
                {label ? <Label text={label} /> : null}
                <InputDiv>
                    <DatePicker
                        disabled={isDisabled}
                        onChange={onLocalChange}
                        value={value ? new Date(value) : null}
                    />
                </InputDiv>
            </Wrapper>
        );
    }
};

export default observer(DatePickerInput);
