// Core services
import IApplicationStore from '../interfaces/IApplicationStore';

class Store implements IApplicationStore {
    // Internals
    public environment: string = null;
    public versionNumber: string = null;

    // Observables

    public setEnvironment = (environment: string): void => {
        this.environment = environment;
    };

    public setVersionNumber = (versionNumber: string): void => {
        this.versionNumber = versionNumber;
    };
}

export default Store;
