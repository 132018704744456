// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Props

export interface ILocalProps {
    isChecked?: boolean;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    label?: string;
    onChange: Function;
    propertyName: string;
    style?: any;
}

// End Props

// Styled components

const Wrapper: any = styled.div`
    display: flex;
    flex-direction: column;
`;

// End Styled components

const CheckboxInput: React.StatelessComponent<ILocalProps> = (props): JSX.Element => {
    const { isChecked, isDisabled, isReadOnly, label, onChange, propertyName, style } = props;

    const onChangeLocal = (event: any): void => {
        onChange(propertyName, event.target.checked);
    };

    return (
        <Wrapper id="checkbox-wrapper" style={style}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isChecked}
                        disabled={!!isDisabled || !!isReadOnly}
                        onChange={onChangeLocal}
                    />
                }
                label={label}
            />
        </Wrapper>
    );
};

export default observer(CheckboxInput);
