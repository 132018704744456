// Core services and utilities
import HttpService from './httpService';

const createNode = (entity: any, propertyName: string, entityType: string): any => {
    switch (entityType) {
        default:
            return {
                key: entity.id,
                title: entity[propertyName],
            };
    }
};

const createHierarchy = (
    items: any[],
    parentId: string,
    propertyName: string,
    isTopLevel: Boolean = false,
    entityType: string
) => {
    const hierarchy: any[] = [];

    function checkIfParent(item: any) {
        return item.parent_Id === parentId;
    }

    items.filter(checkIfParent).map((item) => {
        const node: any = createNode(item, propertyName, entityType);

        const children = createHierarchy(items, item.id, propertyName, isTopLevel, entityType);

        if (children.length) {
            node.hasChildren = true;

            node.children = children;
        }

        hierarchy.push(node);
    });

    return hierarchy;
};

const loadData = (endpoint: string): Promise<any> => {
    return HttpService.get(endpoint).then((responseObject) => {
        return responseObject.error ? null : responseObject.data;
    });
};

class AppData {
    private _arrays: any = {};
    private _hierarchies: any = {};

    public areConfigurationItemsLoaded: boolean;

    public initialize(): Promise<boolean> {
        const loadConfigurationItemsPromise: Promise<boolean> = this.loadConfigurationItems();

        return loadConfigurationItemsPromise;
    }

    public loadConfigurationItems(shouldForce?: boolean): Promise<boolean> {
        if (this.areConfigurationItemsLoaded && !shouldForce) {
            return Promise.resolve(true);
        }

        return loadData('ConfigurationItems/GetActive').then((data) => {
            if (!data) {
                this._arrays.ConfigurationItems = [];
                this._hierarchies.ConfigurationItems = [];
            } else {
                const sortedConfigurationItemsArray: any[] = data.sort((a, b) => {
                    return a.sortOrder === b.sortOrder && a.value === b.value
                        ? 0
                        : a.sortOrder === b.sortOrder
                        ? a.value < b.value
                            ? -1
                            : 1
                        : a.sortOrder < b.sortOrder
                        ? -1
                        : 1;
                });

                const configurationItemsHierarchy: any[] = createHierarchy(
                    sortedConfigurationItemsArray,
                    null,
                    'value',
                    true,
                    'ConfigurationItem'
                );

                this._arrays.ConfigurationItems = sortedConfigurationItemsArray;
                this._hierarchies.ConfigurationItems = configurationItemsHierarchy;

                this.areConfigurationItemsLoaded = true;
            }

            return true;
        });
    }

    public get configurationItemsArray(): any[] {
        return this._arrays.ConfigurationItems;
    }

    public get configurationItemsHierarchy(): any[] {
        return this._hierarchies.ConfigurationItems;
    }
}

const appData = new AppData();
export default appData;
