// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Local components
import Label from './Label';
import UnstyledLabel from './UnstyledLabel';

// Props

export interface ILocalProps {
    dataValue?: string;
    style?: any;
    text: string;
}

// End Props

// Styled components

const Wrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
    padding: 0.5em !important;

    input,
    textarea {
        font-size: 1em !important;
        padding: 0.5em 0 0.5em 0.8em !important;

        &::placeholder {
            color: #777 !important;
            font-size: 1em;
            font-weight: normal;
            opacity: 0.75;
        }
    }
`;

const ClearDiv: any = styled.div`
    clear: both;
`;

// End Styled components

const LabelArea: React.StatelessComponent<ILocalProps> = ({
    text,
    style,
    dataValue,
}): JSX.Element => (
    <Wrapper style={style}>
        <Label text={text} />
        <UnstyledLabel text={dataValue} />
        <ClearDiv />
    </Wrapper>
);

export default observer(LabelArea);
