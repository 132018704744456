// React
import * as React from 'react';
import { Link } from 'react-router-dom';

// MobX
import { inject, observer } from 'mobx-react';

// Third-party
import classNames from 'classnames';
import styled, { ClassNames, styles } from '../content/theme';

// Material UI
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Gear from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

// Core components
import DockerImageVersions from '../core/components/dockerImageVersions/Container';
import HttpService, { IResponseObject } from '../core/services/httpService';

// Stores
import IApplicationStore from '../stores/interfaces/IApplicationStore';
import ISecurityStore from '../stores/interfaces/ISecurityStore';

// Images
import icon from '../content/images/AMT-icon-white.png';

// Props and state
export interface ILocalProps {
    applicationStore?: IApplicationStore;
    securityStore?: ISecurityStore;
}

interface ILocalState {
    clientLogo: any;
    anchorEl?: any;
}

// End Props and state

// Styled Components
const Branding: any = styled.div`
    width: 200px;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
`;

const IconButtonGroup: any = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    color: #1255aa;
`;

const VerticalBar: any = styled.div`
    border-left: 0.1em solid #d8d8d8;
    height: 30px;
`;

// End Styled Components

@inject('applicationStore', 'securityStore')
@observer
class Header extends React.Component<ILocalProps & WithStyles<ClassNames>, ILocalState> {
    public state: ILocalState = { anchorEl: null, clientLogo: null };

    //Lifecycle events
    public componentDidMount(): void {
        if (this.props.securityStore.clientId) {
            HttpService.get(`ApplicationData/GetClientLogo/${this.props.securityStore.clientId}`)
                .then((responseObject: IResponseObject) => {
                    return responseObject;
                })
                .then((responseObject) => {
                    if (responseObject && responseObject.data) {
                        this.setClientLogo(responseObject.data.clientLogo);
                    }
                });
        }
    }

    // Helper methods
    private handleMenu = (event: any): void => {
        this.setState({ anchorEl: event.currentTarget });
    };

    private handleClose = (): void => {
        this.setState({ anchorEl: null });
    };

    private setClientLogo = (clientLogo: any): void => {
        if (clientLogo && clientLogo.contentType && clientLogo.fileContents) {
            this.setState({
                clientLogo: `data:${clientLogo.contentType};base64,${clientLogo.fileContents}`,
            });
        } else {
            this.setState({
                clientLogo: null,
            });
        }
    };

    public render() {
        const { applicationStore, securityStore } = this.props;
        const open: any = Boolean(this.state.anchorEl);

        const navigationItems: any[] = [
            {
                key: 'configurationItems',
                name: 'Configuration Items',
                url: '/configurationitems',
                icon: 'fa fa-cubes',
            },
            {
                key: 'databaseTables',
                name: 'Database Tables',
                url: '/databasetables',
                icon: 'fa fa-table',
            },
            {
                key: 'importEntities',
                name: 'Import Entities',
                url: '/importentities',
                icon: 'fa fa-file',
            },
            { key: 'serverErrors', name: 'Server Errors', url: '/servererrors', icon: 'fa fa-bug' },
            { key: 'clientErrors', name: 'Client Errors', url: '/clienterrors', icon: 'fa fa-bug' },
        ];

        const navigationMenuItems: any = navigationItems.map(
            (x: any): JSX.Element => (
                <Link
                    to={x.url}
                    onClick={this.handleClose}
                    key={x.key}
                    style={{ textDecoration: 'none', fontSize: '14px' }}
                >
                    <MenuItem>{x.name}</MenuItem>
                </Link>
            )
        );

        return (
            <Toolbar className={this.props.classes.toolbarHeader} disableGutters>
                <Branding className={this.props.classes.flex}>
                    <Link
                        to="/"
                        style={{
                            color: '#1255AA',
                            fontSize: '20px',
                            fontWeight: 'normal',
                            paddingTop: '5px',
                            marginRight: '-5px',
                            textDecorationLine: 'none',
                        }}
                    >
                        <img
                            src={this.state.clientLogo != null ? this.state.clientLogo : icon}
                            style={
                                this.state.clientLogo
                                    ? {
                                          maxHeight: '30px',
                                          paddingRight: '10px',
                                      }
                                    : {
                                          width: '50px',
                                          paddingRight: '10px',
                                          filter: 'invert(100%)',
                                      }
                            }
                        />
                        Import Tool
                    </Link>
                </Branding>
                <IconButtonGroup>
                    <IconButton
                        aria-haspopup="true"
                        aria-owns={this.state.anchorEl ? 'admin-menu' : null}
                        color="inherit"
                        onClick={this.handleMenu}
                        style={{ padding: '0' }}
                    >
                        <Gear style={{ fontSize: 32 }} />
                    </IconButton>
                    <Menu
                        id="admin-menu"
                        anchorEl={this.state.anchorEl}
                        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        onClose={this.handleClose}
                        open={open}
                        style={{ marginTop: '27px' }}
                    >
                        {navigationMenuItems}
                        <Divider />
                        <MenuItem color="inherit" disabled={true}>
                            <Typography>
                                v
                                <DockerImageVersions
                                    appStoreVersion={applicationStore.versionNumber}
                                    environment={applicationStore.environment}
                                    showOnlyClientVersion={true}
                                />
                            </Typography>
                        </MenuItem>
                    </Menu>
                </IconButtonGroup>
                <VerticalBar />
                {securityStore.icon ? (
                    <Avatar
                        alt={securityStore.firstName + ' ' + securityStore.lastName}
                        className={classNames(
                            this.props.classes.avatar,
                            this.props.classes.bigAvatar
                        )}
                        component={IconButton as any}
                        src={securityStore.icon}
                        style={{ paddingLeft: '15px', paddingBottom: '7px', paddingTop: '7px' }}
                    />
                ) : (
                    <AccountCircle
                        color="primary"
                        style={{
                            fontSize: 46,
                            paddingLeft: '15px',
                            paddingBottom: '7px',
                            paddingTop: '7px',
                        }}
                    />
                )}
                <Typography
                    color="primary"
                    style={{
                        fontSize: '14px',
                        paddingLeft: '10px',
                        paddingRight: '25px',
                        fontWeight: 'normal',
                    }}
                >
                    {securityStore.fullName}
                </Typography>
            </Toolbar>
        );
    }
}

export default withStyles(styles)(Header);
