// React
import * as React from 'react';

// Third-party components
import styled, { MiscStyles } from '../../../content/theme';

// Styled components

const Wrapper = styled.div`
    align-items: center;
    //background-color: #769EBC;
    display: flex;
    height: 100vh;
    justify-content: center;
`;

const ContentWrapper: any = styled.div`
    align-self: flex-start;
    width: 30%;
`;

const FormFieldsWrapper = styled.div`
    background-color: #fff;
    border-radius: 10px;
    border: 3px solid ${MiscStyles.backgrounds.primary};
    margin-top: 5em;
    padding: 0.5em 1em 1em 1em;
`;

const Banner: any = styled.div`
    color: #769ebc;
    font-size: 500%;
    text-align: center;
`;

const Message: any = styled.div`
    color: #769ebc;
    text-align: center;
`;

// End Styled components

const NoMatch: React.StatelessComponent = (): JSX.Element => {
    return (
        <Wrapper>
            <ContentWrapper>
                <FormFieldsWrapper>
                    <Banner>404</Banner>
                    <Message>You have attempted to access a page that does not exist!</Message>
                </FormFieldsWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default NoMatch;
