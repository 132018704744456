let serverURL: string = null;

export interface IResponseObject {
    statusCode: number;
    data: any;
    error: string;
}

const HttpService = {
    getServerURL(): string {
        return serverURL;
    },

    initialize(): Promise<IResponseObject> {
        return fetch('/config.json', {
            method: 'GET',
        })
            .then((response: any) => {
                if (response.status === 401) {
                    return response;
                } else {
                    return response.json();
                }
            })
            .then((responseData: any) => {
                if (responseData) {
                    serverURL = responseData.url;

                    return HttpService.get('ApplicationData');
                } else {
                    return Promise.resolve(null);
                }
            });
    },

    get(url: string, baseURL?: string): Promise<IResponseObject> {
        const responseObject: IResponseObject = { error: null, data: null, statusCode: null };

        baseURL = baseURL ? baseURL : serverURL;
        return fetch(`${baseURL}${url}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                Accept: 'application/json',
            },
        })
            .then((response: any) => {
                responseObject.statusCode = response.status;

                if (response.status === 401 || response.status === 500) {
                    return response;
                } else {
                    return response.json();
                }
            })
            .then((responseData: any) => {
                let error: any;

                if (responseData && responseData.status && responseData.status === 401) {
                    error = 'Unauthorized. You will need to log in.';
                } else if (responseData && responseData.status && responseData.status === 500) {
                    error = 'Internal Server Error';
                } else if (responseData && responseData.error) {
                    error = responseData.error;
                }

                if (!error) {
                    responseObject.data = responseData;
                } else {
                    responseObject.error = error;
                }

                return responseObject;
            })
            .catch((error) => {
                responseObject.error = `${error}`;
                return responseObject;
            });
    },

    post(
        url: string,
        data: any,
        isJsonPost: boolean = true,
        baseURL?: string
    ): Promise<IResponseObject> {
        baseURL = baseURL ? baseURL : serverURL;

        const responseObject: IResponseObject = { error: null, data: null, statusCode: null };
        const headers: any = {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        };

        if (isJsonPost) {
            headers.Accept = 'application/json';
            headers['Content-Type'] = 'application/json';
        }

        return fetch(`${baseURL}${url}`, {
            method: 'POST',
            headers,
            body: isJsonPost ? JSON.stringify(data) : data,
        })
            .then((response: any) => {
                responseObject.statusCode = response.status;

                if (response.status === 401 || response.status === 500 || response.status === 304) {
                    return response;
                } else {
                    return response.json();
                }
            })
            .then((responseData: any) => {
                let error: any;

                if (responseData && responseData.status && responseData.status === 401) {
                    error = 'Unauthorized. You will need to log in.';
                } else if (responseData && responseData.status && responseData.status === 500) {
                    error = 'Internal Server Error';
                } else if (responseData && responseData.status && responseData.status === 304) {
                    error = 'Data not modified.';
                } else if (responseData && responseData.error) {
                    error = responseData.error;
                } else if (!responseData) {
                    error = 'No data was returned';
                }

                if (!error) {
                    responseObject.data = responseData;
                } else {
                    responseObject.error = error;
                }

                return responseObject;
            })
            .catch((error) => {
                responseObject.error = `${error}`;
                return responseObject;
            });
    },
};

export default HttpService;
