// React
import * as React from 'react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import { Link } from 'react-router-dom';

// Props

export interface ILocalProps {
    breadCrumbs: IBreadCrumbItem[];
}

// End Props

// Styled components

const Wrapper: any = styled.div`
    display: flex;
    flex-flow: row nowrap;
    float: left;
    padding-left: 2em;
    padding-top: 1.3em;
`;

const BreadCrumbLink: any = styled(Link)`
    color: ${({ theme }) => theme.themes.default.primary};
    font-size: 120%;

    &:hover {
        color: ${({ theme }) => theme.themes.default.secondary};
    }
`;

const BreadCrumbCurrent: any = styled.div`
    color: ${({ theme }) => theme.themes.default.secondary};
    font-size: 120%;
`;

// End Styled components

export interface IBreadCrumbItem {
    key: string;
    name: string;
    route?: string;
}

const BreadCrumb = (props: ILocalProps) => {
    const { breadCrumbs } = props;

    return (
        <Wrapper>
            {breadCrumbs.map((x: any, idx: number) => {
                return (
                    <React.Fragment key={x.key}>
                        {idx > 0 ? <BreadCrumbCurrent>&nbsp;/&nbsp;</BreadCrumbCurrent> : null}
                        {x.route ? (
                            <BreadCrumbLink to={x.route}>{x.name}</BreadCrumbLink>
                        ) : (
                            <BreadCrumbCurrent> {x.name}</BreadCrumbCurrent>
                        )}
                    </React.Fragment>
                );
            })}
        </Wrapper>
    );
};

export default BreadCrumb;
