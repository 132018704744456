// Base class
import BaseDataAPI from './BaseDataAPI';

// APIs
import IJobDefinitionAPI from '../interfaces/IJobDefinitionAPI';

// Core services
import HttpService, { IResponseObject } from '../../core/services/httpService';

class JobDefinitionAPI extends BaseDataAPI implements IJobDefinitionAPI {
    constructor() {
        super('JobDefinitions');
    }

    public deleteFile = (id: string): Promise<IResponseObject> => {
        return HttpService.post(`SourceDefinitionFiles/Delete/${id}`, {}).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public getJobRunSummariesByJobDefinitionId = (id: string): Promise<any> => {
        return HttpService.get(`JobRuns/GetJobRunSummariesByJobDefinitionId/${id}`).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public getMappingSummaryByJobDefinitionId = (id: string): Promise<any> => {
        return HttpService.get(`JobDefinitions/GetMappingSummaryByJobDefinitionId/${id}`).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public getImportDefinitionMappingSummaryByJobDefinitionId = (id: string): Promise<any> => {
        return HttpService.get(
            `JobDefinitions/GetImportDefinitionMappingSummaryByJobDefinitionId/${id}`
        ).then((responseObject: IResponseObject) => {
            return responseObject;
        });
    };

    public getSourceDefinitionFilesByJobDefinitionId = (id: string): Promise<any> => {
        return HttpService.get(
            `JobDefinitions/GetSourceDefinitionFilesByJobDefinitionId/${id}`
        ).then((responseObject: IResponseObject) => {
            return responseObject;
        });
    };

    public runJob = (data: any): Promise<any> => {
        return HttpService.post('JobDefinitions/RunJob', data).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };
}

export default JobDefinitionAPI;
