// Third party
import { action, computed, observable, runInAction } from 'mobx';

// Core services
import CommonUtilities from '../../../core/utilities/commonUtilities';

// IResponseObject
import { IResponseObject } from '../../../core/services/httpService';

// Stores
import IStore from './IStore';

// APIs
import IJobDefinitionAPI from '../../../apis/interfaces/IJobDefinitionAPI';

class JobDefinitionCollectionStore implements IStore {
    // Internals
    private api: IJobDefinitionAPI;

    // Observables
    @observable
    public shouldShowActiveItemsOnly: boolean = true;

    @observable
    public isBusy: boolean = false;

    @observable
    private _items: any[] = [];

    // Computeds
    @computed
    public get items(): any[] {
        return this._items.slice();
    }

    // Functions
    constructor(api: IJobDefinitionAPI) {
        this.api = api;

        this.isBusy = true;

        this.getItems().then(() => {
            runInAction(() => {
                this.isBusy = false;
            });
        });
    }

    // Actions
    @action
    private getItems = async (): Promise<any> => {
        const getPromise: Promise<IResponseObject> = this.shouldShowActiveItemsOnly
            ? this.api.getActive()
            : this.api.getAll();

        const responseObject: IResponseObject = await getPromise;

        return runInAction(() => {
            if (!responseObject.error) {
                if (!responseObject.data) {
                    this._items = [];
                } else {
                    this._items = responseObject.data.map((x) => {
                        return {
                            ...x,
                            createDateTime: x.createDateTime
                                ? new Date(new Date(x.createDateTime).setHours(0, 0, 0, 0))
                                : null,
                        };
                    });
                }
            } else {
                CommonUtilities.showDataLoadErrorOccurred();
                this._items = [];
            }
        });
    };

    @action
    public toggleShouldShowActiveItemsOnly = (): void => {
        this.shouldShowActiveItemsOnly = !this.shouldShowActiveItemsOnly;
        this.getItems();
    };
}

export default JobDefinitionCollectionStore;
