// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Local components
import ClearFix from '../layout/ClearFix';
import { Button } from '..';
import LeftButtonWrapper from '../styledComponents/LeftButtonWrapper';
import RightButtonWrapper from '../styledComponents/RightButtonWrapper';

// Props and state

export interface ILocalProps {
    addButtonId?: string;
    addButtonText?: string;
    buttonSize?: 'small' | 'medium' | 'large';
    exportToExcel?: Function;
    extraLeftButtons?: any[];
    extraRightButtons?: any[];
    isBusy: boolean;
    isShowingArchivedItems?: boolean;
    onAdd?: Function;
    selectedItemHasChanges: boolean;
    shouldShowAddButton: boolean;
    toggleShowArchivedItems?: any;
}

// End Props and state

// Styled components

const Wrapper: any = styled.div`
    margin-bottom: 0.5em;
`;

// End Styled components

const GridButtonBar: React.StatelessComponent<ILocalProps> = (props): JSX.Element => {
    const {
        addButtonId,
        addButtonText,
        buttonSize,
        onAdd,
        exportToExcel,
        extraLeftButtons,
        extraRightButtons,
        isBusy,
        isShowingArchivedItems,
        shouldShowAddButton,
        toggleShowArchivedItems,
    } = props;

    const leftButtons: any[] = extraLeftButtons ? extraLeftButtons.slice() : [];
    if (shouldShowAddButton) {
        leftButtons.push(
            <Button
                color="primary"
                content={addButtonText || 'Add New'}
                iconName="plus"
                id={addButtonId}
                isDisabled={isBusy}
                key="addNew"
                onClick={onAdd}
                size={buttonSize || 'medium'}
            />
        );
    }

    const rightButtons: any[] = extraRightButtons ? extraRightButtons.slice() : [];

    if (toggleShowArchivedItems) {
        rightButtons.push(
            <Button
                id="show-archived-items"
                content={isShowingArchivedItems ? 'Hide Archived Items' : 'Show Archived Items'}
                isDisabled={isBusy}
                key="toggleShowArchivedItems"
                onClick={toggleShowArchivedItems}
                size={buttonSize || 'medium'}
                variant="outlined"
            />
        );
    }

    if (exportToExcel) {
        rightButtons.push(
            <Button
                id="export-to-excel"
                content="Export to Excel"
                isDisabled={isBusy}
                key="exportToExcel"
                onClick={exportToExcel}
                size={buttonSize || 'medium'}
                variant="outlined"
            />
        );
    }

    return (
        <Wrapper>
            <LeftButtonWrapper>{leftButtons}</LeftButtonWrapper>
            <RightButtonWrapper>{rightButtons}</RightButtonWrapper>
            <ClearFix />
        </Wrapper>
    );
};

export default observer(GridButtonBar);
