// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import TextField from '@material-ui/core/TextField';

// Local components
import ClearFix from '../layout/ClearFix';
import Label from '../forms/Label';

// Props

export interface ILocalProps {
    error?: boolean;
    id?: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    isPassword?: boolean;
    isReadOnly?: boolean;
    isRequired?: boolean;
    label: string;
    maxLength: number;
    maxRows?: number;
    minRows?: number;
    onChange?: Function;
    onPressEnter?: any;
    propertyName: string;
    ref?: any;
    style?: any;
    validate?: boolean | Function;
    value: string;
}

// End Props

// Styled components

const Wrapper: any = styled.div`
    display: flex;
    flex-direction: column;
`;

// End Styled components

export const TextAreaInput = (props: ILocalProps) => {
    const {
        error,
        id,
        isDisabled,
        isHidden,
        isPassword,
        isReadOnly,
        isRequired,
        label,
        maxLength,
        maxRows,
        onChange,
        onPressEnter,
        propertyName,
        style,
        value,
    } = props;

    const onChangeLocal = (event: any): void => {
        if (onChange) {
            onChange(propertyName, event.target.value);
        }
    };

    const onKeyPress = (event: any): void => {
        if (onPressEnter && event.charCode === 13) {
            onPressEnter();
        }
    };

    if (!isHidden) {
        if (isReadOnly) {
            return (
                <Wrapper>
                    <div>
                        <div>
                            <Label text={label} />
                        </div>
                        <div>{value}</div>
                    </div>
                </Wrapper>
            );
        } else {
            return (
                <Wrapper style={style}>
                    {maxLength > 250 ? (
                        <TextField
                            disabled={isDisabled}
                            error={error === true || error === false ? error : isRequired && !value}
                            id={id || ''}
                            inputProps={{ maxLength: maxLength }}
                            label={label}
                            margin="normal"
                            multiline
                            onChange={onChangeLocal}
                            onKeyPress={onKeyPress}
                            required={isRequired}
                            rows={maxLength > 500 ? (maxRows ? maxRows : 4) : 1}
                            value={value || ''}
                        />
                    ) : (
                        <TextField
                            disabled={isDisabled}
                            error={error === true || error === false ? error : isRequired && !value}
                            id={id || ''}
                            inputProps={{ maxLength: maxLength }}
                            label={label}
                            margin="normal"
                            onChange={onChangeLocal}
                            onKeyPress={onKeyPress}
                            required={isRequired}
                            type={isPassword ? 'password' : 'text'}
                            value={value || ''}
                        />
                    )}
                    <ClearFix />
                </Wrapper>
            );
        }
    } else {
        return <div />;
    }
};

export default observer(TextAreaInput);
