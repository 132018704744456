// React
import * as React from 'react';

// MobX
import { inject, observer } from 'mobx-react';

// Third-party components
import styled from '../../content/theme';

// Core components
import BreadCrumb, { IBreadCrumbItem } from '../../core/components/forms/BreadCrumb';
import CollectionScreenGrid from '../../core/components/grids/CollectionScreenGrid';
import ToolbarWrapper from '../../core/components/styledComponents/ToolbarWrapper';

// Stores
import Store from './stores/Store';
import IStore from './stores/IStore';

import ISecurityStore from '../../stores/interfaces/ISecurityStore';
import { RouterStore } from 'mobx-react-router';

// APIs
import JobDefinitionAPI from '../../apis/implementations/JobDefinitionAPI';
import IJobDefinitionAPI from '../../apis/interfaces/IJobDefinitionAPI';

// Props and state

interface ILocalProps {
    routerStore?: RouterStore;
    securityStore?: ISecurityStore;
}

// End Props and state

// Styled components

const Wrapper: any = styled.div`
    margin: 1em;
`;

// End Styled components

const defaultSort: any[] = [{ dir: 'asc', field: 'name' }];
const baseDetailScreenPath: string = '/jobdefinition';
const excelExportName: string = 'Job Definitions';

const columns: any[] = [
    {
        field: 'name',
        key: 'name',
        title: 'Name',
        width: 400,
    },
    {
        field: 'description',
        key: 'description',
        title: 'Description',
    },
    {
        field: 'createDateTime',
        filter: 'date',
        format: '{0:MM/dd/yyyy}',
        key: 'createDateTime',
        title: 'Date Created',
        width: 230,
    },
];

@inject('routerStore', 'securityStore')
@observer
export class HomeContainer extends React.Component<ILocalProps> {
    private store: IStore;
    private canViewArchivedData: boolean;
    private canAdd: boolean;

    constructor(props: ILocalProps) {
        super(props);

        this.canViewArchivedData = true;
        this.canAdd = true;

        // this.canViewArchivedData = this.props.securityStore.userHasPermission(
        //     'FUNCTION.VIEW_ARCHIVED_DATA',
        //     this.props.securityStore.rights.Read
        // );

        // this.canAdd = this.props.securityStore.userHasPermission(
        //     'ENTITY.VALIDATION_RULE_TYPE',
        //     this.props.securityStore.rights.Write
        // );

        const api: IJobDefinitionAPI = new JobDefinitionAPI();
        this.store = new Store(api);
    }

    private buildBreadCrumbs = (): IBreadCrumbItem[] => {
        const breadCrumbs: IBreadCrumbItem[] = [];

        const homeBreadCrumb: IBreadCrumbItem = {
            key: 'home',
            name: 'Home',
        };

        breadCrumbs.push(homeBreadCrumb);

        return breadCrumbs;
    };

    public render() {
        const { store } = this;

        return (
            <div>
                <ToolbarWrapper>
                    <BreadCrumb breadCrumbs={this.buildBreadCrumbs()} />
                </ToolbarWrapper>
                <Wrapper>
                    <CollectionScreenGrid
                        addButtonId="add-import-definition"
                        addButtonText="Add an Import Definition"
                        baseDetailScreenPath={baseDetailScreenPath}
                        canAdd={this.canAdd}
                        columns={columns}
                        data={store.items}
                        defaultSort={defaultSort}
                        excelExportName={excelExportName}
                        isDisabled={false}
                        isShowingArchivedItems={
                            this.canViewArchivedData ? !store.shouldShowActiveItemsOnly : false
                        }
                        toggleShowArchivedItems={
                            this.canViewArchivedData ? store.toggleShouldShowActiveItemsOnly : null
                        }
                    />
                </Wrapper>
            </div>
        );
    }
}

export default HomeContainer;