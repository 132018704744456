// CRA Polyfill for IE11
import 'react-app-polyfill/ie11';

// CoreJs polyfills for IE11
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/symbol';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';

// React
import * as React from 'react';
import * as ReactDOM from 'react-dom';

// Routing
import createBrowserHistory from 'history/createBrowserHistory';
import { Provider as MobxProvider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';

// Third-party libraries
import { configure } from 'mobx';

// Toaster
import { ToastContainer, Zoom } from 'react-toastify';

// Third-party CSS
// Telerik Kendo
import './content/kendo.css';

// ReactToastify
import 'react-toastify/dist/ReactToastify.min.css';

// AntDesign
import 'antd/lib/style/index.css';
import 'antd/lib/tree/style/index.css';

// Font Awesome icons
import 'font-awesome/css/font-awesome.min.css';

// Local CSS
import './content/site.css';
import { ThemeProvider, ThemeStyles } from './content/theme';

// Core services
import ErrorLogger from './core/utilities/errorLogger';
import HttpService from './core/services/httpService';

// Local components
import App from './App';

// MobX Stores
import ApplicationStore from './stores/implementations/ApplicationStore';
import IApplicationStore from './stores/interfaces/IApplicationStore';
import ISecurityStore from './stores/interfaces/ISecurityStore';
import SecurityStore from './stores/implementations/SecurityStore';

// Set MobX to strict mode
configure({ enforceActions: 'observed' });

// Build router connected to MobX
const browserHistory = createBrowserHistory();
const routerStore: RouterStore = new RouterStore();

const applicationStore: IApplicationStore = new ApplicationStore();
const securityStore: ISecurityStore = new SecurityStore();

// Set up main MobX store
const stores: any = {
    applicationStore,
    routerStore,
    securityStore,
};

const history = syncHistoryWithStore(browserHistory, routerStore);

function renderApp() {
    ReactDOM.render(
        <MobxProvider {...stores}>
            <ThemeProvider theme={ThemeStyles}>
                <Router history={history}>
                    <>
                        <App />
                        <ToastContainer autoClose={4000} transition={Zoom} />
                    </>
                </Router>
            </ThemeProvider>
        </MobxProvider>,
        document.getElementById('root') as HTMLElement
    );
}

HttpService.initialize().then((responseObject) => {
    if (responseObject && responseObject.data) {
        applicationStore.setEnvironment(responseObject.data.environment);
        applicationStore.setVersionNumber(responseObject.data.version);
    }

    securityStore.getAssertion().then(() => {
        renderApp();
    });
});

window.onerror = function(errorMsg: string, url: string, lineNumber: number) {
    ErrorLogger.logWindowOnError(`${errorMsg} in ${url}`, lineNumber);
};
