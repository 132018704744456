// React
import React from 'react';
import ReactDOM from 'react-dom';

// Props

interface ILocalProps {
    isLoading: boolean;
}

// End Props

class LoadingPanel extends React.Component<ILocalProps> {
    constructor(props: ILocalProps) {
        super(props);
    }

    render() {
        const loadingPanel = (
            <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image" />
                <div className="k-loading-color" />
            </div>
        );

        const gridContent = document && document.querySelector('.k-grid-content');
        return gridContent && this.props.isLoading
            ? ReactDOM.createPortal(loadingPanel, gridContent)
            : '';
    }
}

export default LoadingPanel;
