// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Props
interface IGridWrapperProps extends React.HTMLProps<HTMLDivElement> {
    shouldDim: boolean;
    shouldShowStandardCursor?: boolean;
}

// End Props

const gridWrapperDiv: React.StatelessComponent<IGridWrapperProps> = ({ className, children }) => (
    <div className={className}>{children}</div>
);

const GridWrapper = styled(gridWrapperDiv)`
    cursor: ${({ shouldDim, shouldShowStandardCursor }) =>
        (shouldDim && 'no-drop !important;') || (shouldShowStandardCursor ? 'default' : 'pointer')};
    flex: 1;
    opacity: ${({ shouldDim }) => (shouldDim && 0.5) || 1.0};
`;

export default observer(GridWrapper);
