// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import TextField from '@material-ui/core/TextField';

// Local components
import Label from '../forms/Label';

// Styled components

const Wrapper: any = styled.div`
    display: flex;
    flex-direction: column;
`;

// End Styled components

// Props and state

interface ILocalProps {
    id?: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    isReadOnly?: boolean;
    isRequired?: boolean;
    label: string;
    max?: number;
    min?: number;
    onChange: Function;
    onPressEnter?: any;
    precision: number;
    propertyName: string;
    style?: any;
    value: number;
}

// End Props and state

const tryFormat = (formatterValue: any, precision: number): string => {
    const regex: RegExp = new RegExp(`\\-{0,1}\\d*\\.{0,1}\\d{0,${precision}}`, 'g');

    const match: RegExpExecArray = regex.exec(formatterValue);
    if (!match) {
        return '';
    }

    return `${match ? match[0] : 0}`;
};

const NumericInput = (props: ILocalProps): JSX.Element => {
    const {
        id,
        isDisabled,
        isHidden,
        isReadOnly,
        isRequired,
        label,
        max,
        min,
        onChange,
        onPressEnter,
        precision,
        propertyName,
        style,
        value,
    } = props;

    const onKeyPress = (event: any): void => {
        if (onPressEnter && event.charCode === 13) {
            onPressEnter();
        }
    };

    const onChangeLocal = (event: any): void => {
        let newValue: number = event.target.value;

        if (newValue < min) {
            newValue = min;
        } else if (newValue > max) {
            newValue = max;
        }

        onChange(propertyName, tryFormat(newValue, precision));
    };

    if (!isHidden) {
        if (isReadOnly) {
            return (
                <Wrapper>
                    <div>
                        <div>{label && <Label text={label} />}</div>
                        <div>{tryFormat(value, precision)}</div>
                    </div>
                </Wrapper>
            );
        } else {
            const inputStyle: any = style ? style : {};

            return (
                <Wrapper>
                    <TextField
                        disabled={!!isDisabled || !!isReadOnly}
                        error={isRequired && value === null}
                        id={id || ''}
                        label={label}
                        margin="normal"
                        onChange={onChangeLocal}
                        onKeyPress={onKeyPress}
                        required={isRequired}
                        style={inputStyle}
                        type="number"
                        value={value || 0}
                    />
                </Wrapper>
            );
        }
    } else {
        return <div />;
    }
};

export default observer(NumericInput);
