const deepClone = (obj: any): any => {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null === obj || 'object' !== typeof obj) {
        return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());

        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];

        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepClone(obj[i]);
        }

        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                copy[key] = deepClone(obj[key]);
            }
        }

        return copy;
    }

    throw new Error(`Unable to copy obj! Its type isn't supported.`);
};

const DataUtilities = {
    addEntityAspectToBaseEntity(entity: any, entityTypeName: string): any {
        entity.entityAspect = {
            entityTypeName: entityTypeName,
            entityState: 'Unchanged',
            originalValuesMap: {},
        };

        return entity;
    },

    deepClone,

    revertChangesToPOJOEntity(entity: any): any {
        Object.keys(entity.entityAspect.originalValuesMap).forEach((key) => {
            entity[key] = entity.entityAspect.originalValuesMap[key];
        });

        entity.entityAspect.originalValuesMap = {};

        if (entity.entityAspect.entityState !== DataUtilities.entityStates.Added) {
            entity.entityAspect.entityState = DataUtilities.entityStates.Unchanged;
        }
    },

    entityStates: {
        Added: 'Added',
        Deleted: 'Deleted',
        Detached: 'Detached',
        Modified: 'Modified',
        Unchanged: 'Unchanged',
    },

    updateOriginalPOJOValuesMap(entity: any, propertyName: string): void {
        if (!entity.entityAspect.originalValuesMap.hasOwnProperty(propertyName)) {
            entity.entityAspect.originalValuesMap[propertyName] = entity[propertyName];
        }

        DataUtilities.setEntityIsModified(entity);
    },

    setEntityIsModified(entity: any): void {
        if (
            entity.entityAspect &&
            entity.entityAspect.entityState &&
            entity.entityAspect.entityState === DataUtilities.entityStates.Unchanged
        ) {
            entity.entityAspect.entityState = DataUtilities.entityStates.Modified;
        }
    },

    setEntityIsUnchanged(entity: any): void {
        entity.entityAspect.entityState = DataUtilities.entityStates.Unchanged;
        entity.entityAspect.originalValuesMap = {};
    },
};

export default DataUtilities;
