// Third-party components
import { Workbook } from '@progress/kendo-ooxml';
import { drawDOM, exportPDF, exportImage } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';

// Core utilities and services
import CommonUtilities from './commonUtilities';

const ScreenUtilities = {
    saveGridAsXLSX(rows: any[], fileName: string, tabName: string): void {
        const columnCount: number = rows.length && rows[0].cells ? rows[0].cells.length : 0;

        const columns: any[] = [];
        for (let counter: number = 0; counter < columnCount; counter++) {
            columns.push({ autoWidth: true });
        }

        const workbook = new Workbook({
            sheets: [
                {
                    columns,
                    name: tabName,
                    rows,
                },
            ],
        });

        workbook.toDataURL().then((xlsxData) => {
            saveAs(xlsxData, fileName);
        });
    },

    exportScreenshot(
        printAreaId: string,
        exportFileName: string,
        exportFormat: string,
        onExportCompleteCallback: Function
    ) {
        drawDOM(document.getElementById(printAreaId), {})
            .then((group) => {
                if (exportFormat === CommonUtilities.exportFormats.PNG) {
                    // Render the result as a PNG image file
                    return exportImage(group, null);
                } else {
                    // Render the result as a PDF file
                    return exportPDF(group, null);
                }
            })
            .then((data) => {
                // Save the file
                saveAs(data, `${exportFileName}.${exportFormat}`);

                onExportCompleteCallback();
            });
    },
};

export default ScreenUtilities;
