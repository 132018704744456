// Core services and utilities
import IDataAPI from '../../apis/interfaces/IDataAPI';
import ClientErrorAPI from '../../apis/implementations/ClientErrorAPI';

const api: IDataAPI = new ClientErrorAPI();

const ErrorLogger = {
    logError(error: any): Promise<any> {
        const newClientError: any = {
            componentStack: null,
            lineNumber: 0,
            message: error.message,
            stackTrace: error.stack,
        };

        return api.add(newClientError);
    },

    logWindowOnError(message: string, lineNumber: number): Promise<any> {
        const newClientError: any = {
            componentStack: null,
            lineNumber,
            message,
            stackTrace: null,
        };

        return api.add(newClientError);
    },

    logComponentDidCatchError(error: any, info: any): Promise<any> {
        const newClientError: any = {
            componentStack: info.componentStack,
            lineNumber: 0,
            message: error.message,
            stackTrace: error.stack,
        };

        return api.add(newClientError);
    },
};

export default ErrorLogger;
