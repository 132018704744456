// MobX
import { action, computed, observable, toJS } from 'mobx';

// Kendo
import { filterBy, orderBy } from '@progress/kendo-data-query';

// Core services
import DataUtilities from '../../core/utilities/dataUtilities';

// Stores
import IGridStore, { IGridStoreParameters } from '../interfaces/IGridStore';

class GridStore implements IGridStore {
    // Observables
    @observable
    private _data: any[] = [];

    @observable
    public isBusy: boolean = true;

    @observable
    public skip: number = 0;

    @observable
    public take: number = 20;

    @observable
    public filter: any = {
        logic: 'and',
        filters: [],
    };

    @observable
    public sort: any[] = [];

    // Computeds
    @computed
    public get filteredSortedData(): any[] {
        if (!this._data || !this._data.length) {
            return [];
        }

        const filter: any = DataUtilities.deepClone(toJS(this.filter));
        const sort: any[] = this.sort ? this.sort.slice() : [];

        var newDateFilter: any = null;
        var filterDateTwice: boolean = false;

        // Parse the current filter collection and make modifications as needed to the date filter
        if (filter != null && filter.filters.length > 0) {
            filter.filters.forEach((element) => {
                if (element.value instanceof Date) {
                    switch (element.operator) {
                        case 'eq':
                            // clone the current filter so we can add a new one to convert 'eq' into one day range
                            newDateFilter = DataUtilities.deepClone(element);
                            newDateFilter.operator = 'lt';
                            newDateFilter.value.setDate(newDateFilter.value.getDate() + 1);

                            // convert the current filter to >=
                            element.operator = 'gte';
                            break;
                        case 'neq':
                            // Create a new filter and create a (< DAY || >= DAY+1) filter
                            filterDateTwice = true;
                            newDateFilter = DataUtilities.deepClone(element);
                            newDateFilter.operator = 'gte';
                            newDateFilter.value.setDate(newDateFilter.value.getDate() + 1);

                            // convert the current filter to <
                            element.operator = 'lt';
                            break;
                        case 'lte':
                            // Convert filter into < DAY+1
                            element.value.setDate(element.value.getDate() + 1);
                            element.operator = 'lt';
                            break;
                        case 'gt':
                            // Convert filter into >= DAY+1
                            element.value.setDate(element.value.getDate() + 1);
                            element.operator = 'gte';
                            break;
                        default:
                            // do nothing because the current functionality works as expected on the timestamp
                            break;
                    }
                }
            });
        }

        if (newDateFilter) {
            if (filterDateTwice) {
                // To make this NEQ filter work on a range, we need to apply the filter twice, then concat the results.
                // The first filter is GTG, however, newDateFilter is only the modified clone of the specific date filter, so we need
                // to ensure that the second filter that gets applied also contains any of the additional column filters
                // I.e., (DateFilter1 AND otherColumnFilters) OR (DateFilter2 AND otherColumnFilters)
                var newFilterCollection: any = DataUtilities.deepClone(filter);
                newFilterCollection.filters
                    .slice()
                    .reverse()
                    .forEach(function(item, index, object) {
                        if (item.value instanceof Date) {
                            newFilterCollection.filters.splice(object.length - 1 - index, 1);
                        }
                    });

                newFilterCollection.filters.push(newDateFilter);

                // Apply both filters independently, then concat the results
                var data1: any[] = filterBy(this._data.slice(), filter);
                var data2: any[] = filterBy(this._data.slice(), newFilterCollection);
                return orderBy(data1.concat(data2), sort);
            } else {
                filter.filters.push(newDateFilter);
            }
        }

        return orderBy(filterBy(this._data.slice(), filter), sort);
    }

    @computed
    public get pagedData(): any[] {
        return this.filteredSortedData.slice(this.skip, this.skip + this.take);
    }

    @computed
    public get itemCount(): number {
        return this.filteredSortedData.length;
    }

    // Functions
    constructor(params: IGridStoreParameters) {
        this._data = params.data;
        this.sort = params.defaultSort ? params.defaultSort : [];

        if (params.defaultTake) {
            this.take = params.defaultTake;
        }
    }

    // Actions
    @action
    public setData = (data: any[]): void => {
        this._data = data;
        this.skip = 0;
    };

    @action
    public onFilterChange = (event: any): void => {
        this.skip = 0;
        this.filter = event.filter;
    };

    @action
    public onSortChange = (event: any): void => {
        this.sort = event.sort;
    };

    @action
    onPageChange = (event: any): void => {
        this.skip = event.page.skip;
        this.take = event.page.take;
    };

    @action
    public onClearAllFilters = (): void => {
        this.filter = {
            logic: 'and',
            filters: [],
        };
    };

    @action
    public onRowClick = (event: any): void => {
        this._data.forEach((item) => (item.selected = false));

        const selectedRow: any = this._data.find((dataItem) => dataItem === event.dataItem);
        if (selectedRow) {
            selectedRow.selected = true;
        }
    };

    @action
    public setState = (skip: number, take: number, sort: any[], filter: any): void => {
        this.skip = skip;
        this.take = take;
        this.sort = sort;
        this.filter = filter;
    };
}

export default GridStore;
