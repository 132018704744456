// React
import * as React from 'react';

// MobX
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import { Grid, GridColumn } from '@progress/kendo-react-grid';

// Core utilities and services
import ScreenUtilities from '../../utilities/screenUtilities';

// Core components
import GridButtonBar from '../forms/GridButtonBar';
import GridWrapper from './GridWrapper';
import LoadingPanel from './LoadingPanel';

// Stores
import Store from '../../../stores/implementations/GridStore';
import IGridStore, { IGridStoreParameters } from '../../../stores/interfaces/IGridStore';
import { action, toJS } from 'mobx';

// Styled components

const Wrapper8px: any = styled.div`
    margin: 12px 8px 4px 8px;
`;

// End Styled components

// Props and state

interface ILocalProps {
    addButtonId?: string;
    addButtonText?: string;
    baseDetailScreenPath?: string;
    buttonSize?: 'small' | 'medium' | 'large';
    canAdd: boolean;
    canDeleteItem?: boolean;
    columns: any[];
    data: any[];
    defaultSort: any[];
    excelExportName?: string;
    extraLeftButtons?: any[];
    extraRightButtons?: any[];
    isBusy?: boolean;
    isDisabled: boolean;
    isShowingArchivedItems?: boolean;
    onAdd?: Function;
    onRowClick?: Function;
    routerStore?: RouterStore;
    shouldShowStandardCursor?: boolean;
    style?: any;
    toggleShowArchivedItems?: any;
}

// End Props and state

const pagerSettings: any = {
    buttonCount: 5,
    info: true,
    pageSizes: [5, 10, 20, 50, 100, 250],
    previousNext: true,
    type: 'numeric',
};

@inject('routerStore')
@observer
export class CollectionScreenGrid extends React.Component<ILocalProps> {
    private store: IGridStore;

    constructor(props: ILocalProps) {
        super(props);

        const { data, defaultSort } = this.props;

        const params: IGridStoreParameters = {
            data,
            defaultSort,
        };

        this.store = new Store(params);
    }

    // Lifecycle events

    public componentWillReceiveProps(nextProps: ILocalProps) {
        if (nextProps.data && (!this.props.data || this.props.data !== nextProps.data)) {
            // refresh grid
            this.store.setData(nextProps.data);
        }

        if (
            this.props.routerStore.history.action == 'POP' &&
            this.props.routerStore.location.state
        ) {
            // restore the state from the history
            this.store.setState(
                this.props.routerStore.location.state.skip,
                this.props.routerStore.location.state.take,
                this.props.routerStore.location.state.sort,
                this.props.routerStore.location.state.filter
            );
        }
    }

    // End Lifecycle events

    private exportToExcel = (): void => {
        const rows: any[] = [
            {
                cells: this.props.columns.map((column) => {
                    return { value: column.title };
                }),
            },
        ];

        const selectedRows: any[] = [
            {
                cells: this.props.columns.map((column) => {
                    return { value: column.title };
                }),
            },
        ];

        let hasSelectedRows: boolean = false;

        this.store.filteredSortedData.forEach((item) => {
            if (item.selected) {
                hasSelectedRows = true;
                selectedRows.push({
                    cells: this.props.columns.map((column) => {
                        return { value: item[column.field] };
                    }),
                });
            }

            if (!hasSelectedRows) {
                rows.push({
                    cells: this.props.columns.map((column) => {
                        return { value: item[column.field] };
                    }),
                });
            }
        });

        ScreenUtilities.saveGridAsXLSX(
            hasSelectedRows ? selectedRows : rows,
            `${this.props.excelExportName}.xlsx`,
            this.props.excelExportName
        );
    };

    private addItem = (): void => {
        if (!this.props.isDisabled && this.props.canAdd) {
            this.props.routerStore.push(`${this.props.baseDetailScreenPath}/new`);
        }
    };

    public onRowClick = (event: any): void => {
        if (!this.props.isDisabled) {
            this.store.onRowClick(event);

            if (this.props.onRowClick) {
                this.props.onRowClick(event.dataItem);
            } else if (this.props.baseDetailScreenPath) {
                this.props.routerStore.history.push(this.props.routerStore.location.pathname, {
                    skip: this.store.skip,
                    take: this.store.take,
                    sort: toJS(this.store.sort),
                    filter: toJS(this.store.filter),
                });
                this.props.routerStore.push(
                    `${this.props.baseDetailScreenPath}/${event.dataItem.id}`
                );
            }
        }
    };

    public onFilterChange = (event: any): void => {
        if (!this.props.isDisabled) {
            this.store.onFilterChange(event);
        }
    };

    public onSortChange = (event: any): void => {
        if (!this.props.isDisabled) {
            this.store.onSortChange(event);
        }
    };

    @action
    public selectionChange = (event) => {
        event.dataItem.selected = !event.dataItem.selected;
        this.forceUpdate();
    };

    @action
    public headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.store.pagedData.forEach((item) => (item.selected = checked));
        this.forceUpdate();
    };

    public render() {
        const { store } = this;

        const {
            addButtonId,
            addButtonText,
            buttonSize,
            canAdd,
            columns,
            excelExportName,
            extraLeftButtons,
            extraRightButtons,
            isDisabled,
            isShowingArchivedItems,
            onAdd,
            shouldShowStandardCursor,
            style,
            toggleShowArchivedItems,
            canDeleteItem,
            isBusy,
        } = this.props;

        let gridStyle: any = style;
        if (!gridStyle) {
            gridStyle = { height: 'calc(100vh - 184px)' };
        }

        // Ugly hack used to create a reference to the selected row so an update gets triggered when it changes
        // MobX needs to know we are watching that value
        // The Kendo Grid doesn't bind to it. Instead it accepts a selectedField magic string
        // The empty if is needed to the TS compiler doesn't flag the selectedRow variable as unused and prevent compilation
        const selectedRow = store.filteredSortedData.find((dataItem) => dataItem.selected);
        if (selectedRow) {
            //
        }

        let checkboxColumn: JSX.Element;
        if (canDeleteItem) {
            checkboxColumn = (
                <GridColumn
                    field="selected"
                    width="50px"
                    filterable={false}
                    headerSelectionValue={
                        this.store.pagedData.findIndex(
                            (dataItem) => dataItem.selected === false
                        ) === -1
                    }
                />
            );
        }

        return (
            <Wrapper8px>
                <GridButtonBar
                    addButtonId={addButtonId ? addButtonId : 'add-item-button'}
                    addButtonText={addButtonText}
                    buttonSize={buttonSize}
                    exportToExcel={excelExportName ? this.exportToExcel : null}
                    extraLeftButtons={extraLeftButtons}
                    extraRightButtons={extraRightButtons}
                    isBusy={false}
                    isShowingArchivedItems={isShowingArchivedItems}
                    onAdd={onAdd ? onAdd : this.addItem}
                    selectedItemHasChanges={false}
                    shouldShowAddButton={canAdd}
                    toggleShowArchivedItems={toggleShowArchivedItems}
                />
                <GridWrapper
                    shouldDim={isDisabled}
                    shouldShowStandardCursor={shouldShowStandardCursor}
                >
                    <Grid
                        data={store.pagedData}
                        filter={store.filter}
                        filterable={true}
                        onFilterChange={this.onFilterChange}
                        onPageChange={store.onPageChange}
                        onRowClick={this.onRowClick}
                        onSortChange={this.onSortChange}
                        pageSize={store.take}
                        pageable={pagerSettings}
                        resizable={true}
                        selectedField="selected"
                        skip={store.skip}
                        sortable={{
                            allowUnsort: true,
                            mode: 'multiple',
                        }}
                        sort={store.sort.slice()}
                        style={gridStyle}
                        total={store.itemCount}
                        onSelectionChange={this.selectionChange}
                        onHeaderSelectionChange={this.headerSelectionChange}
                    >
                        {checkboxColumn}

                        {columns.map((column: any) => {
                            if (column.kendoGridColumn) {
                                return column.kendoGridColumn;
                            }

                            return (
                                <GridColumn
                                    cell={column.cell ? column.cell : null}
                                    field={column.field}
                                    filter={column.filter ? column.filter : 'text'}
                                    format={column.format ? column.format : null}
                                    key={column.field}
                                    title={column.title}
                                    width={column.width}
                                />
                            );
                        })}
                    </Grid>
                </GridWrapper>
                <LoadingPanel isLoading={isBusy} />
            </Wrapper8px>
        );
    }
}

export default CollectionScreenGrid;
