// Toaster
import { toast } from 'react-toastify';

class NotificationService {
    public showError(message: any): void {
        toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
        });
    }

    public showSuccess(message: any): void {
        toast.success(message, {
            position: toast.POSITION.TOP_CENTER,
        });
    }

    public showWarning(message: any): void {
        toast.warn(message, {
            position: toast.POSITION.TOP_CENTER,
        });
    }

    public showInfo(message: any, duration?: number, positionBottomRight?: boolean): void {
        toast.info(message, {
            position: positionBottomRight ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.TOP_CENTER,
            autoClose: duration ? duration : 60000,
        });
    }

    public showCommands(message: any): void {
        toast.info(message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
        });
    }

    public dismissAll(): void {
        toast.dismiss();
    }
}

const notificationService = new NotificationService();
export default notificationService;
