// Base class
import BaseDataAPI from './BaseDataAPI';

class ClientErrorAPI extends BaseDataAPI {
    constructor() {
        super('ClientErrors');
    }
}

export default ClientErrorAPI;
