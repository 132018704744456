// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Props

export interface ILocalProps {
    text: string;
}

// End Props

// Styled components

const StyledLabel: any = styled.label`
    font-size: 1.1em !important;
    padding-bottom: 0.4em !important;
`;

// End Styled components

const UnstyledLabel: React.StatelessComponent<ILocalProps> = ({ text }) => (
    <StyledLabel>{text}</StyledLabel>
);

export default observer(UnstyledLabel);
