// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import { Button } from '..';

// Local components
import ClearFix from '../layout/ClearFix';

// Props and state

export interface ILocalProps {
    archiveItem?: Function;
    buttonSize?: 'small' | 'medium' | 'large';
    canCancel: boolean;
    canArchive: boolean;
    canDelete?: boolean;
    canMove?: boolean;
    canSave: boolean;
    canUnarchive: boolean;
    cancel: Function;
    deleteItem?: Function;
    isBusy: boolean;
    move?: Function;
    save: Function;
    selectedItemHasChanges: boolean;
    style?: any;
    unarchiveItem?: Function;
}

// End Props and state

// Styled components

const Wrapper: any = styled.div`
    margin-bottom: 0.5em;
    padding: 0 1em;
`;

const ButtonWrapper: any = styled.div`
    display: flex;
    flex-direction: row;
    float: right;
`;

const StatusSpan: any = styled.span`
    font-size: 155%;
    font-weight: bold;
    padding-right: 15px;
    padding-top: 8px;
`;

const StatusActive: any = styled(StatusSpan)`
    color: green;
`;

const StatusInactive: any = styled(StatusSpan)`
    color: red;
`;

// End Styled components

const showStatus = (canArchive: boolean, canUnarchive: boolean): JSX.Element => {
    if (canArchive) {
        return <StatusActive>Active</StatusActive>;
    } else if (canUnarchive) {
        return <StatusInactive>Archived</StatusInactive>;
    } else {
        return null;
    }
};

const ItemDetailButtonBar: React.StatelessComponent<ILocalProps> = (props): JSX.Element => {
    const {
        archiveItem,
        buttonSize,
        canCancel,
        canArchive,
        canDelete,
        canMove,
        canSave,
        canUnarchive,
        cancel,
        deleteItem,
        isBusy,
        move,
        save,
        selectedItemHasChanges,
        unarchiveItem,
    } = props;

    let archiveButton: JSX.Element;
    if (canArchive && archiveItem) {
        archiveButton = (
            <Button
                className="archive"
                content="Archive"
                iconName="archive"
                isDisabled={isBusy}
                onClick={archiveItem}
                size={buttonSize || 'medium'}
            />
        );
    }

    let unarchiveButton: JSX.Element;
    if (canUnarchive && unarchiveItem) {
        unarchiveButton = (
            <Button
                className="unarchve"
                content="Unarchive"
                iconName="recycle"
                isDisabled={isBusy}
                onClick={unarchiveItem}
                size={buttonSize || 'medium'}
            />
        );
    }

    let deleteButton: JSX.Element;
    if (canDelete && deleteItem) {
        deleteButton = (
            <Button
                content="Delete"
                iconName="trash-o"
                isDisabled={isBusy}
                onClick={deleteItem}
                size={buttonSize || 'medium'}
            />
        );
    }

    return (
        <Wrapper>
            <ButtonWrapper>
                {showStatus(canArchive, canUnarchive)}
                {deleteButton}
                {archiveButton}
                {unarchiveButton}
                {canMove && (
                    <Button
                        content={'Move'}
                        iconName="arrows"
                        isDisabled={!canMove || isBusy}
                        onClick={move}
                        size={buttonSize || 'medium'}
                    />
                )}
                <Button
                    className="cancel-button"
                    content="Cancel"
                    iconName="undo"
                    isDisabled={!canCancel || !selectedItemHasChanges || isBusy}
                    onClick={cancel}
                    size={buttonSize || 'medium'}
                />
                <Button
                    className="save-button"
                    color="primary"
                    content="Save"
                    iconName="save"
                    isDisabled={!canSave || isBusy}
                    onClick={save}
                    size={buttonSize || 'medium'}
                />
            </ButtonWrapper>
            <ClearFix />
        </Wrapper>
    );
};

export default observer(ItemDetailButtonBar);
