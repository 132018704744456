// React
import * as React from 'react';

// MobX
import { observer } from 'mobx-react';

// Third-party components
import styled from '../../../content/theme';

// Third-party UI components
import FontAwesome from 'react-fontawesome';

// Styled components

const Wrapper: any = styled.div`
    cursor: pointer;
    font-size: 12px;
    opacity: ${(props: any) => (props.isDisabled && 0.5) || 1.0};
    padding: 0.3em 0.5em;
    text-align: center;
    white-space: nowrap;
`;

// End Styled components

// Props and State

interface ILocalProps {
    isDisabled: boolean;
    iconName: string;
    onClick?: Function;
    size: 'lg' | '2x' | '3x' | '4x' | '5x';
    textLabel: string;
}

// End Props and State

const ToolbarButton: React.StatelessComponent<ILocalProps> = (props): JSX.Element => {
    const { isDisabled, iconName, onClick, size, textLabel } = props;

    const handleClick = (): void => {
        if (!isDisabled && onClick) {
            onClick();
        }
    };

    return (
        <Wrapper isDisabled={isDisabled} onClick={handleClick}>
            <FontAwesome name={iconName} size={size} />
            <div>
                <div>{textLabel}</div>
            </div>
        </Wrapper>
    );
};

export default observer(ToolbarButton);
