// React
import * as React from 'react';
import Loadable from 'react-loadable';

// React Router
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

// Local components
import PrivateRoute from './PrivateRoute';
import Loading from '../core/components/screenLoadingSpinner/Container';
import NoMatch from '../core/components/404/Container';

const loading: any = Loading as any;

// Screens

import HomeScreen from '../features/homeScreen/Container';

// Admin
const APIField = Loadable({
    loader: () =>
        import(/* webpackChunkName: "apiFieldContainer" */ '../admin/apiFields/detail/Container'),
    loading,
});

const APIFieldMappings = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "apiFieldMappingsContainer" */ '../features/apiFieldMappings/Container'
        ),
    loading,
});

const ClientError = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "clientErrorContainer" */ '../admin/clientErrors/detail/Container'
        ),
    loading,
});

const ClientErrors = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "clientErrorsContainer" */ '../admin/clientErrors/collection/Container'
        ),
    loading,
});

const ConfigurationItems = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "configurationItemsContainer" */ '../admin/configurationItems/Container'
        ),
    loading,
});

const DatabaseField = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "databaseFieldContainer" */ '../admin/databaseFields/detail/Container'
        ),
    loading,
});

const DatabaseFieldMappings = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "databaseFieldMappingsContainer" */ '../features/databaseFieldMappings/Container'
        ),
    loading,
});

const DatabaseTable = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "databaseTableContainer" */ '../admin/databaseTables/detail/Container'
        ),
    loading,
});

const DatabaseTables = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "databaseTablesContainer" */ '../admin/databaseTables/collection/Container'
        ),
    loading,
});

const ImportEntities = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "importEntitiesContainer" */ '../admin/importEntities/Container'
        ),
    loading,
});

const ImportDefinitions = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "importDefinitionsContainer" */ '../features/importDefinitions/addedit/Container'
        ),
    loading,
});

const JobDefinition = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "jobDefinitionContainer" */ '../features/jobDefinitions/detail/Container'
        ),
    loading,
});

const JobRun = Loadable({
    loader: () =>
        import(/* webpackChunkName: "jobRunContainer" */ '../features/jobRuns/detail/Container'),
    loading,
});

const ServerError = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "serverErrorContainer" */ '../admin/serverErrors/detail/Container'
        ),
    loading,
});

const ServerErrors = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "serverErrorsContainer" */ '../admin/serverErrors/collection/Container'
        ),
    loading,
});

// TODO - security

class Routes extends React.Component<RouteComponentProps<any>> {
    public render() {
        return (
            <Switch>
                <PrivateRoute path="/" component={HomeScreen} exact />

                <PrivateRoute
                    path="/apifield/:id/:importEntityId?/:importEntityName?/:importEntityTab?"
                    component={APIField}
                    exact
                    // controlPointName="MENU.API_FIELDS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/apifieldmappings/:jobdefinitionid/:importdefinitionid"
                    component={APIFieldMappings}
                    // controlPointName="MENU.JOB_DEFINITIONS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/clienterror/:id"
                    component={ClientError}
                    // controlPointName="MENU.CLIENT_ERRORS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/clienterrors"
                    component={ClientErrors}
                    // controlPointName="MENU.CLIENT_ERRORS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/configurationitems"
                    component={ConfigurationItems}
                    // controlPointName="MENU.CONFIGURATION_ITEMS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/databasefield/:id/:databaseTableId?/:databaseTableName?/:databaseTableTab?/:importEntityId?/:importEntityName?/:importEntityTab?"
                    component={DatabaseField}
                    exact
                    // controlPointName="MENU.DATABASE_FIELDS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/databasefieldmappings/:jobdefinitionid/:importdefinitionid"
                    component={DatabaseFieldMappings}
                    // controlPointName="MENU.JOB_DEFINITIONS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/databasetables"
                    component={DatabaseTables}
                    exact
                    // controlPointName="MENU.DATABASE_TABLES"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/databasetable/:id/:tab?/:importEntityId?/:importEntityName?/:importEntityTab?"
                    component={DatabaseTable}
                    exact
                    // controlPointName="MENU.DATABASE_TABLES"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/importdefinitions/:id"
                    component={ImportDefinitions}
                    // controlPointName="MENU.JOB_DEFINITIONS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/importentities/:id?/:tab?"
                    component={ImportEntities}
                    // controlPointName="MENU.IMPORT_ENTITIES"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/jobdefinition/:id"
                    component={JobDefinition}
                    // controlPointName="MENU.JOB_DEFINITIONS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/jobrun/:jobdefinitionid/:jobrunid"
                    component={JobRun}
                    // controlPointName="MENU.JOB_DEFINITIONS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/servererror/:id"
                    component={ServerError}
                    // controlPointName="MENU.SERVER_ERRORS"
                    // permissionNeeded="CanRead"
                />

                <PrivateRoute
                    path="/servererrors"
                    component={ServerErrors}
                    // controlPointName="MENU.SERVER_ERRORS"
                    // permissionNeeded="CanRead"
                />

                <Route component={NoMatch} />
            </Switch>
        );
    }
}

export default withRouter(Routes);
