// APIs
import IDataAPI from '../interfaces/IDataAPI';

// Core services
import HttpService, { IResponseObject } from '../../core/services/httpService';

class BaseDataAPI implements IDataAPI {
    public endpointName: string;

    constructor(endpointName: string) {
        this.endpointName = endpointName;
    }

    public getActive = (): Promise<IResponseObject> => {
        return HttpService.get(`${this.endpointName}/GetActive`).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public getAll = (): Promise<IResponseObject> => {
        return HttpService.get(`${this.endpointName}/GetAll`).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public getPage = (
        skip: number,
        take: number,
        filter: any,
        sort: any[]
    ): Promise<IResponseObject> => {
        return HttpService.post(`${this.endpointName}/GetPage`, {
            Skip: skip,
            Take: take,
            Filter: filter,
            Sort: sort,
        }).then((responseObject: IResponseObject) => {
            return responseObject;
        });
    };

    public getById = (id: string): Promise<IResponseObject> => {
        return HttpService.get(`${this.endpointName}/GetById/${id}`).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public add = (entity: any): Promise<IResponseObject> => {
        return HttpService.post(`${this.endpointName}/Add/`, entity).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public update = (entity: any): Promise<IResponseObject> => {
        return HttpService.post(`${this.endpointName}/Update/${entity.id}`, entity).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public delete = (entity: any): Promise<IResponseObject> => {
        return HttpService.post(`${this.endpointName}/Delete/${entity.id}`, {}).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };

    public deleteMany = (ids: any[]): Promise<IResponseObject> => {
        return HttpService.post(`${this.endpointName}/Delete/`, ids).then(
            (responseObject: IResponseObject) => {
                return responseObject;
            }
        );
    };
}

export default BaseDataAPI;
