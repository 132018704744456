// React
import * as React from 'react';

// Third-party components
import styled from 'styled-components';

// Styled components

const ClearFixDiv = styled.div`
    &::after {
        clear: both;
        content: '';
        display: table;
    }
`;

// End Styled components

const ClearFix = () => <ClearFixDiv />;

export default ClearFix;
