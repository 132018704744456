// React
import * as React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

// MobX
import { inject, observer } from 'mobx-react';

// Third-party components
import styled from '../content/theme';

// Stores
import ISecurityStore from '../stores/interfaces/ISecurityStore';

// Styled components

const ErrorMessage: any = styled.div`
    font-size: 200%;
    margin-top: 1em;
    text-align: center;
    width: 100%;
`;

// End Styled components

const PrivateRouteWithObserver = observer(
    ({ component: Component, securityStore, controlPointName, permissionNeeded, ...rest }) => (
        <Route
            {...rest}
            render={(props) => {
                if (securityStore.isAuthenticated) {
                    if (controlPointName && permissionNeeded) {
                        if (
                            (securityStore as ISecurityStore).userHasPermission(
                                controlPointName,
                                permissionNeeded
                            )
                        ) {
                            return <Component {...props} />;
                        } else {
                            return (
                                <ErrorMessage>
                                    You do not have permission to view this screen.
                                </ErrorMessage>
                            );
                        }
                    } else {
                        return <Component {...props} />;
                    }
                }

                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    )
);

const PrivateRouteWithSecurityStore = inject('securityStore')(PrivateRouteWithObserver);
const PrivateRoute = withRouter(PrivateRouteWithSecurityStore) as any;

export default PrivateRoute;
